import { NgClass } from '@angular/common';
import {
  AfterViewInit, Component, ElementRef, EventEmitter,
  forwardRef, Input, OnInit, Output, ViewChild
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormControl, ValidationErrors, Validator } from '@angular/forms';
import { INTL_TEL_INPUT, PREFERRED_COUNTRIES } from '@constants/app.constants';
import { distinctUntilChanged } from 'rxjs';

declare var intlTelInput: any;

@Component({
  selector: 'app-calio-tel-input',
  templateUrl: './calio-tel-input.component.html',
  styleUrls: ['./calio-tel-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalioTelInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CalioTelInputComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [FormsModule, NgClass, ReactiveFormsModule]
})

export class CalioTelInputComponent implements OnInit, ControlValueAccessor, Validator, AfterViewInit {

  constructor() {
  }

  @Input() defaultCountry: string;
  @Input() public cssClass: {};
  @Input() public E164PhoneNumber: string;
  @Input() public label: string;
  @Input() public labelCssClass: string;
  @Input() public name = 'intl-tel-input-name';
  @Input() public onlyLocalized = true;
  @Input() public options: any = {};
  @Input() public required = false;
  @Input() public isDisabled = false;

  @Output() public blurEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('intlTelInput') private _inputElement: ElementRef;

  private _intlTelInput: any;
  private onTouch: Function;
  private onModelChange: Function;
  private onValidatorChange: Function;
  readonly preferredCountries = PREFERRED_COUNTRIES;

  telInputControl: UntypedFormControl = new UntypedFormControl();

  private static modifyCountryData(): void {
    (window as any).intlTelInput.getCountryData().forEach((country: any) =>
      country.name = country.name.replace(/.+\((.+)\)/, '$1'));
  }

  ngOnInit(): void {
    this.telInputControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((inputValue: string) => {
        if (!inputValue) {
          if (this.onModelChange) {
            this.onModelChange(inputValue);
          }
        }
        if (inputValue && this.onModelChange) {
          this.setPhoneNumber(inputValue);
          if (this.onTouch && !this.telInputControl.touched) {
            this.onTouch();
          }
          if (this.onValidatorChange) {
            this.onValidatorChange();
          }
        }
      }
      );

    if (this.isDisabled) {
      this.telInputControl.disable();
    }
  }

  onClick(): void {
    if (this.onTouch) {
      this.onTouch();
    }
  }

  onBlur(): void {
    if (this.onTouch) {
      this.onTouch();
    }
    this.blurEvent.emit();
  }

  public ngAfterViewInit(): void {
    this.onlyLocalized && CalioTelInputComponent.modifyCountryData();

    this.options = {
      initialCountry: 'auto',
      separateDialCode: true,
      preferredCountries: this.preferredCountries,
      formatOnDisplay: true,
      autoHideDialCode: true,
      phoneValidation: true,
      loadUtilsOnInit: INTL_TEL_INPUT.UTIL_SCRIPTS,
      validationNumberType: "FIXED_LINE_OR_MOBILE",
      countrySearch: false,
      geoIpLookup: callback => {
        fetch(INTL_TEL_INPUT.IPLOOKUP_URL)
          .then(res => res.json())
          .then(data => callback(data.country))
          .catch(() => callback("ch"));
      },
    };

    const intlTelInputInstance = intlTelInput;
    this._intlTelInput = intlTelInputInstance(this._inputElement.nativeElement, this.options);
  }

  setPhoneNumber(value: string): void {
    if (this._intlTelInput) {
      if (!!value) {
        this._intlTelInput.setNumber(value);
      }
      this.i18nizePhoneNumber();
    } else {
    }
  }

  public i18nizePhoneNumber(): void {
    if (Number(this._intlTelInput.telInput.style.paddingLeft.replace('px', '')) > 100) {
      const widthOfCountryDialCode = this._intlTelInput.selectedDialCode.parentElement.clientWidth;
      this._intlTelInput.telInput.style.paddingLeft = `${(widthOfCountryDialCode + 3.97)}px`;
    }
    this.E164PhoneNumber = this._intlTelInput.getNumber();
    if (this.E164PhoneNumber) {
      this.onModelChange(this.E164PhoneNumber);
    } else {
      if (this._intlTelInput.selectedDialCode && this._intlTelInput.selectedDialCode.innerText) {
        if (this.telInputControl.value && this.telInputControl.value[0] === '+') {
          this.E164PhoneNumber = this._intlTelInput.selectedDialCode.innerText + this._intlTelInput.telInput.value;
        } else {
          this.E164PhoneNumber = this._intlTelInput.selectedDialCode.innerText + (this.telInputControl.value ? this.telInputControl.value : '');
        }
        this.onModelChange(this.E164PhoneNumber);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.telInputControl.setValue(obj);
  }

  validate(control: AbstractControl): ValidationErrors {
    if (control.value) {
      const selectedCountryCode = this._intlTelInput.getSelectedCountryData();
      if (control.value === `+${selectedCountryCode.dialCode}`) {
        if (this.required) {
          control.setErrors({
            required: true
          });
          return control.errors;
        }
      } else if (!this._intlTelInput.isValidNumber() && this._intlTelInput.isValidNumber() !== null) {
        control.setErrors({
          invalidNumber: true
        });
        return control.errors;
      }
    }
    return null;
  }

  registerOnValidatorChange?(fn: any): void {
    this.onValidatorChange = fn;
  }
}
