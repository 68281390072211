import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BOOKING_PAGE_VIEWS, GTM_EVENTS, PAYMENT_TYPES } from '@constants/app.constants';
import { PaymentMethodViewComponent } from '@features/booking/components/payment-method-view/payment-method-view.component';
import { AppointmentBookingModel, BookingPageViews } from '@models/appointment-booking.model';
import { AppointmentReservationBody } from '@models/appointment-reservation.model';
import { CouponDbModel } from '@models/coupon-db.model';
import { MeetingTypesModel } from '@models/meeting-types.model';
import { AppointmentCartItem } from '@models/selected-booking-data.model';
import { CurrentViewData } from '@models/widget-conf.model';
import { WidgetTemplateModel } from '@models/widget-template.model';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioCurrencyPipe } from '@pipes/calio-currency.pipe';
import { CalioDateUtcPipe } from '@pipes/calio-date-utc.pipe';
import { CalioMeetingTemplatePipe } from '@pipes/calio-meeting-template.pipe';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { BookingService } from '@services/feature/booking.service';
import { CustomEventService } from '@services/feature/custom-event.service';
import { WidgetService } from '@services/feature/widget.service';
import { GoogleAnalyticsService } from '@services/utils/google-analytics.service';
import { LoggerService } from '@services/utils/logger.service';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-booking-summary-view',
  templateUrl: './booking-summary-view.component.html',
  styleUrls: ['./booking-summary-view.component.scss'],
  standalone: true,
  imports: [CswTextComponent, NgTemplateOutlet, PaymentMethodViewComponent, ButtonComponent, DatePipe, TranslateModule, CalioCurrencyPipe, CalioSafePipe, CalioTranslationPipe, CalioDateUtcPipe, CalioMeetingTemplatePipe]
})
export class BookingSummaryViewComponent implements OnInit, OnDestroy {

  @Output() nextPageEvent = new EventEmitter<CurrentViewData>();
  @Output() previousPageEvent = new EventEmitter<CurrentViewData>();
  @Output() gotoPageEvent = new EventEmitter<any>();

  protected lang: string;
  protected subTotal = 0;
  protected totalAmount = 0;
  protected showCoupon = false;
  protected couponCode: string;
  protected finalCouponDiscount = 0;
  protected templateTitle: WidgetTemplateModel;
  protected templateSubTitle: WidgetTemplateModel;
  protected initialSmartWidgetView: string;
  protected selectedMeetingType: MeetingTypesModel;

  private couponSuccessSubscription$: Subscription;
  private couponFailedSubscription$: Subscription;
  private doBookingSubscription$: Subscription;
  protected readonly paymentTypes = PAYMENT_TYPES;

  @ViewChild('bookingSummaryView') bookingSummaryView: ElementRef;

  constructor(
    public translate: TranslateService,
    public bookingService: BookingService,
    private customEventService: CustomEventService,
    public widgetService: WidgetService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe({
      next: (language: LangChangeEvent) => {
        this.lang = language.lang;
      }
    });

    this.couponSuccessSubscription$ = this.customEventService.couponSuccessEvent.subscribe({
      next: (result: CouponDbModel) => {
        this.bookingService.selectedBookingData.selectedCoupon = result;
        this.calculateCoupon(result);
        this.calculateTotalPrice();
        this.showCoupon = true;
        this.resetPaymentGatewayData();
      }
    });
    this.couponFailedSubscription$ = this.customEventService.couponFailedEvent.subscribe({
      next: (result: {
        disableBookingButton: boolean,
        invalidCoupon: boolean,
      }) => {
        if (this.bookingService.selectedBookingData.selectedCoupon) {
          this.removeCoupon();
        }
      }
    });

    this.doBookingSubscription$ = this.customEventService.doBookingEvent.subscribe({
      next: (result: any) => {
        this.book();
      }
    });
    this.initialSmartWidgetView = this.bookingService.initialSmartWidgetView;
  }

  ngOnInit(): void {
    this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_summary);
    LoggerService.log('this.bookingService.selectedBookingData ', this.bookingService.selectedBookingData);
    this.calculateSubTotalPrice();
    this.calculateTotalPrice();
    this.setupTemplates();
  }

  calculateSubTotalPrice(): void {
    let subTotal: number = Number(0);
    for (const cartItem of this.bookingService.selectedBookingData.cartItems) {
      if (cartItem?.service?.price > 0) {
        subTotal = subTotal + cartItem.service.price;
      }
    }
    this.subTotal = subTotal;
    this.bookingService.selectedBookingData.subTotal = this.subTotal;
  }

  calculateTotalPrice(): void {
    if (this.finalCouponDiscount > 0) {
      this.totalAmount = this.subTotal - this.finalCouponDiscount;
    } else {
      this.totalAmount = this.subTotal;
    }
    this.totalAmount = Number(this.totalAmount.toFixed(2));
    this.bookingService.selectedBookingData.totalBookingAmount = this.totalAmount;
  }

  calculateCoupon(coupon: CouponDbModel): void {
    this.couponCode = coupon.code;
    if (coupon && coupon.is_fixed_amount === 1) {
      this.finalCouponDiscount = Number(coupon.fixed_amount);
    }

    if (coupon && coupon.is_percentage === 1) {
      this.finalCouponDiscount = Number((Number(this.subTotal) / 100 * coupon.percentage).toFixed(2));
    }

    this.bookingService.selectedBookingData.totalDiscount = this.finalCouponDiscount;
  }

  book(): void {
    const bookingItems: AppointmentBookingModel[] = [];

    for (const cartItem of this.bookingService.selectedBookingData.cartItems) {
      const bookingData: AppointmentBookingModel = {
        partnerId: this.bookingService.partnerData.id,
        services: cartItem.serviceId + '',
        worker_id: cartItem.workerId,
        worker_uuids: cartItem.workerUuids,
        start: cartItem.startTime,
        end: cartItem.endTime,
        prename: this.bookingService.selectedBookingData.selectedUser.forename,
        gender: this.bookingService.selectedBookingData.selectedUser.gender,
        lastname: this.bookingService.selectedBookingData.selectedUser.lastName,
        email: this.bookingService.selectedBookingData.selectedUser.eMail,
        phone: this.bookingService.selectedBookingData.selectedUser.phone,
        mobile: this.bookingService.selectedBookingData.selectedUser.mobile,
        comment: this.bookingService.selectedBookingData.selectedUser.comment,
        customer_id: this.bookingService.selectedBookingData.selectedUser.customerId,
        company: this.bookingService.selectedBookingData.selectedUser?.company,
        company_name: this.bookingService.selectedBookingData.selectedUser?.company,
        title: this.bookingService.selectedBookingData.selectedUser?.title,
        country: this.bookingService.selectedBookingData.selectedUser?.country,
        customer_notification_preference: this.bookingService.selectedBookingData.selectedUser.customer_notification_preference,
        meeting_type_id: (cartItem.selectedMeetingTypeId || this.bookingService.selectedBookingData.selectedUser?.meeting_type_id),
        customFieldValues: this.bookingService.selectedBookingData.selectedCustomFieldValues,
        stripe_card_token: this.bookingService.selectedBookingData.paymentMethodData.stripePaymentMethodId ? this.bookingService.selectedBookingData.paymentMethodData.stripePaymentMethodId : null,
        coupon_code: this.bookingService.selectedBookingData.selectedCoupon ? this.bookingService.selectedBookingData.selectedCoupon.code : null,
        payment_type: this.bookingService.selectedBookingData.paymentMethodData.paymentType,
        street: this.bookingService.selectedBookingData.selectedUser.street,
        zip: this.bookingService.selectedBookingData.selectedUser.zip,
        city: this.bookingService.selectedBookingData.selectedUser.city,
        captcha: this.bookingService.selectedBookingData.selectedUser?.captcha || undefined,
        token: this.bookingService.selectedBookingData?.captcha?.uuid || undefined
      };

      if (this.bookingService.selectedBookingData.paymentMethodData.paymentType === this.paymentTypes.PAYPAL) {
        bookingData.paypal_order_id = this.bookingService.selectedBookingData.paymentMethodData.paypalOrderDetail.paypal_order_id;
        bookingData.paypal_payer_id = this.bookingService.selectedBookingData.paymentMethodData.paypalOrderDetail.paypal_payer_id;
        bookingData.paypal_status = this.bookingService.selectedBookingData.paymentMethodData.paypalOrderDetail.paypal_status;
        bookingData.paypal_raw_data = this.bookingService.selectedBookingData.paymentMethodData.paypalOrderDetail.paypal_raw_data;
        bookingData.paypal_capture_id = this.bookingService.selectedBookingData.paymentMethodData.paypalOrderDetail.paypal_capture_id;
      }

      if (this.widgetService?.widgetConf?.context?.locale) {
        bookingData.user_browser_language = this.widgetService?.widgetConf?.context?.locale;
        bookingData.user_selected_language = this.widgetService?.widgetConf?.context?.locale;
      } else if (this.widgetService?.widgetConf && this.widgetService?.widgetConf?.locale) {
        bookingData.user_browser_language = this.widgetService?.widgetConf?.locale;
        bookingData.user_selected_language = this.widgetService?.widgetConf?.locale;
      }

      this.widgetService?.widgetConf?.context?.utm_source && (bookingData.utm_source = this.widgetService.widgetConf.context.utm_source);
      this.widgetService?.widgetConf?.context?.utm_campaign && (bookingData.utm_campaign = this.widgetService.widgetConf.context.utm_campaign);
      this.widgetService?.widgetConf?.context?.utm_medium && (bookingData.utm_medium = this.widgetService.widgetConf.context.utm_medium);
      this.widgetService?.widgetConf?.context?.utm_content && (bookingData.utm_content = this.widgetService.widgetConf.context.utm_content);
      this.widgetService?.widgetConf?.context?.utm_term && (bookingData.utm_term = this.widgetService.widgetConf.context.utm_term);
      bookingItems.push(bookingData);
    }

    this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking);

    this.bookingService.bookAppointments(bookingItems).subscribe({
      next: (result: { success: boolean, errors: { message: string }[] }) => {
        if (result.success) {
          LoggerService.log('Booking completed');
          this.bookingService.resetBookingDataAfterBooking();
          this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.BOOKING_SUCCESS_VIEW, isUserInteracted: true });

          if (this.bookingService.hasFeature('is_external_success_page_forwarding_enabled')) {
            if (
              this.bookingService.partnerData.is_redirect_after_booking_enabled &&
              this.bookingService.partnerData.successful_booking_redirect_url
            ) {
              window.open(this.bookingService.partnerData.successful_booking_redirect_url, '_blank');
            }
          }
        } else {
          LoggerService.log('Booking failed');
          this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.BOOKING_FAILED_VIEW, isUserInteracted: true });
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
        this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.BOOKING_FAILED_VIEW, isUserInteracted: true });
      }
    });
  }

  previous(): void {
    this.previousPageEvent.emit({ view: BOOKING_PAGE_VIEWS.PERSONAL_FORM_VIEW, isUserInteracted: true });
  }

  onAdditionalService(): void {
    // @TODO(Pratik): Have to rectify this code once we have setting for clearning context on add additional service

    // Resetting state values as user is booking another appointment
    this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedDate = undefined;

    if (true) {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId = undefined;
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorker = undefined;
      this.widgetService.widgetConf.context.worker_email = undefined;
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds = [];
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServices = [];
    } else {
      if (this.widgetService?.widgetConf?.context?.store_id === undefined) {
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId = undefined;
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore = undefined;
      }

      if (
        this.widgetService?.widgetConf?.context?.appointment_service_ids === undefined ||
        this.widgetService?.widgetConf?.context?.appointment_service_ids?.length === 0
      ) {
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds = [];
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServices = [];
      }

      if (
        this.widgetService?.widgetConf?.context?.worker_id === undefined ||
        this.widgetService?.widgetConf?.context?.worker_email === undefined ||
        this.widgetService?.widgetConf?.context?.worker_uuid === undefined
      ) {
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId = undefined;
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorker = undefined;
      }

      if (this.widgetService?.widgetConf?.context?.store_id === undefined) {
        this.gotoPageEvent.emit({ view: BOOKING_PAGE_VIEWS.STORE_VIEW, isUserInteracted: true });
      } else if (
        this.widgetService?.widgetConf?.context?.appointment_service_ids === undefined ||
        this.widgetService?.widgetConf?.context?.appointment_service_ids?.length === 0
      ) {
        this.gotoPageEvent.emit({ view: BOOKING_PAGE_VIEWS.SERVICES_VIEW, isUserInteracted: true });
      } else if (this.widgetService?.widgetConf?.context?.worker_id === undefined) {
        this.gotoPageEvent.emit({ view: BOOKING_PAGE_VIEWS.WORKERS_VIEW, isUserInteracted: true });
      } else {
        this.gotoPageEvent.emit({ view: BOOKING_PAGE_VIEWS.SLOTS_VIEW, isUserInteracted: true });
      }
    }

    let redirectToView: BookingPageViews = BOOKING_PAGE_VIEWS.SERVICES_VIEW;
    if (this.widgetService?.widgetConf?.context?.show_meeting_type_picker) {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId = undefined;
      redirectToView = BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW;
    }
    // update state in local storage
    this.bookingService.setAppointmentStateInLocalStorage(this.bookingService.selectedBookingData.currentSelectedAppointmentState);

    this.gotoPageEvent.emit({ view: redirectToView, isUserInteracted: true });
  }

  removeCartItem(index: number): void {
    // Remove reserved appointment when setting is enabled
    if (Number(this.bookingService.partnerData.is_appointment_reservation_enabled) === 1) {
      const appointment = this.bookingService.selectedBookingData.cartItems[index];
      appointment?.isAppointmentReserved && this.removeReservedAppointment(this.bookingService.selectedBookingData.cartItems[index]);
    }

    this.bookingService.selectedBookingData.cartItems.splice(index, 1);
    this.calculateSubTotalPrice();

    if (this.bookingService.selectedBookingData.cartItems?.length === 0) {
      this.bookingService.resetBookingDataAfterBooking();
      this.gotoPageEvent.emit({ view: this.initialSmartWidgetView, isUserInteracted: true });
    }

    if (this.bookingService.selectedBookingData.selectedCoupon) {
      this.calculateCoupon(this.bookingService.selectedBookingData.selectedCoupon);
    }

    this.calculateTotalPrice();
    this.resetPaymentGatewayData();
  }

  private removeReservedAppointment(appointment: AppointmentCartItem): void {
    const appointmentBody: AppointmentReservationBody = {
      appointment_service_id: appointment.serviceId,
      worker_id: appointment.workerId,
      start_time: appointment.startTime,
      end_time: appointment.endTime
    };

    this.bookingService.removeReservedAppointment([appointmentBody]).subscribe({
      error: (error: HttpErrorResponse) => {
        LoggerService.warn('[Debug] Failed to remove a reserved appointment');
        LoggerService.error(error);
      }
    });
  }

  removeCoupon(): void {
    this.couponCode = undefined;
    this.bookingService.selectedBookingData.selectedCoupon = undefined;
    this.bookingService.selectedBookingData.totalDiscount = 0;
    this.finalCouponDiscount = 0;
    this.calculateTotalPrice();
    this.showCoupon = false;
    this.resetPaymentGatewayData();
    this.customEventService.removeCouponEvent.emit();
  }

  resetPaymentGatewayData(): void {
    if (this.bookingService.selectedBookingData.paymentMethodData.paymentType === this.paymentTypes.PAYPAL) {
      this.customEventService.resetPaypalEvent.emit();
    }
  }

  setupTemplates(): void {
    this.templateTitle = this.bookingService.widgetTemplates.find((template: WidgetTemplateModel) => {
      return template.id === 124;
    });
    if (this.templateTitle) {
      this.templateTitle.is_multi_language = 1;
    }
    this.templateSubTitle = this.bookingService.widgetTemplates.find((template: WidgetTemplateModel) => {
      return template.id === 125;
    });
    if (this.templateSubTitle) {
      this.templateSubTitle.is_multi_language = 1;
    }
  }

  ngOnDestroy(): void {
    if (this.doBookingSubscription$) {
      this.doBookingSubscription$.unsubscribe();
    }
    if (this.couponSuccessSubscription$) {
      this.couponSuccessSubscription$.unsubscribe();
    }
    if (this.couponFailedSubscription$) {
      this.couponFailedSubscription$.unsubscribe();
    }
  }
}

