import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CUSTOM_FIELD_TYPES } from '../../constants/app.constants';
import { IS_CALENSO_AUTH_REQUIRED } from '../../constants/http-context';
import { CalendarAppointmentModel } from '../../models/appointment-booking.model';
import { CustomFieldModel, CustomFieldOptionModel } from '../../models/custom-field.model';
import {
  CustomFieldValueRequestBody, CustomerCustomFieldDbModel, CustomerCustomFieldRequestBody, CustomerDbModel
} from '../../models/customer.model';
import { PartnerDbModel, TokenType } from '../../models/partner-db.model';
import { SurveyCustomerSmartWidgetConfigurationModel } from '../../models/survey-custom-smart-widget-configuration.model';
import { SurveyStepModel } from '../../models/survey-step.model';
import { HttpClientService } from '../utils/http-client.service';
import { LoggerService } from '../utils/logger.service';
import { SwRouteService } from '../utils/sw-route.service';
import { CustomEventService } from './custom-event.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  partnerData: PartnerDbModel;
  surveySteps: SurveyStepModel[] = [];
  customerDetail: CustomerDbModel;
  bookingData: CalendarAppointmentModel[] = [];
  surveyCustomerSWconfigurationData: SurveyCustomerSmartWidgetConfigurationModel;

  constructor(
    private httpClientService: HttpClientService,
    private customEventService: CustomEventService,
    private swRouteService: SwRouteService,
  ) {
  }

  calculateSurveyProgressBarCount(isLast: boolean): void {
    let totalQuestions: number = Number(0);

    for (const surveyStep of this.surveySteps) {
      for (const question of surveyStep.booking_questions) {
        if (question.customerSelectedValue && question.required === 1) {
          totalQuestions = totalQuestions + 1;
        }
      }
    }

    this.customEventService.progressBarCountEvent.emit({
      index: isLast ? totalQuestions + 1 : totalQuestions,
      isLast
    });
  }

  getWidgetSteps(step_ids: number[]): Observable<SurveyStepModel[]> {
    const body: { step_ids: number[] } = { step_ids };
    return this.httpClientService.post('widget_steps/get_by_ids', body, {});
  }


  getFilteredCustomers(uuid: string, email: string, internalToken?: string): Observable<CustomerDbModel[]> {
    const body: {
      uuid?: { value: string, operator: string },
      email_hash?: { value: string, operator: string }
    } = {};

    if (uuid) {
      body.uuid = { value: uuid, operator: '=' };
    } else if (email) {
      body.email_hash = { value: email, operator: '=' };
    } else {
      return of(null);
    }

    return this.httpClientService.post(`customers/filter`, body, { params: { token: internalToken } });
  }

  public updateCustomerCustomField(customerCustomFieldRequestBody: CustomerCustomFieldRequestBody):
    Observable<CustomerCustomFieldDbModel[]> {
    return this.httpClientService.post(`customers/edit_custom_fields`, customerCustomFieldRequestBody, {});
  }

  public createCustomer(customerDbModel: CustomerDbModel): Observable<{ customer: CustomerDbModel }> {
    return this.httpClientService.post(`customers`, customerDbModel, {});
  }

  public exportSurveySummary(
    appointment_uuid: string,
    customer_uuid: string,
    widget_uuid: string,
  ):
    Observable<{
      path: string,
      appointment: CalendarAppointmentModel,
      partner: PartnerDbModel,
      customer: CustomerDbModel,
      widgetSteps: SurveyStepModel[]
    }> {
    const body: {
      appointment_uuid: string,
      customer_uuid: string,
      widget_uuid: string,
    } = {
      appointment_uuid,
      customer_uuid,
      widget_uuid,
    };
    return this.httpClientService.post(`appointments/pdf`, body, {});
  }

  getCustomersSmartWidgetConfigurations(
    customerId: number,
    widgetConfigId: number,
  ): Observable<SurveyCustomerSmartWidgetConfigurationModel[]> {
    const body: any = {};
    if (customerId) {
      body.customer_id = { value: customerId, operator: '=' };
    }
    if (widgetConfigId) {
      body.smartwidget_configuration_id = { value: widgetConfigId, operator: '=' };
    }

    return this.httpClientService.post(
      `customers_smart_widget_configurations/filter`,
      body,
      {});
  }

  createCustomersSmartWidgetConfigurations(
    body: SurveyCustomerSmartWidgetConfigurationModel
  ): Observable<SurveyCustomerSmartWidgetConfigurationModel> {
    return this.httpClientService.post(
      `customers_smart_widget_configurations`,
      body,
      {});
  }

  updateCustomersSmartWidgetConfigurations(
    body: SurveyCustomerSmartWidgetConfigurationModel,
    id: number,
  ): Observable<SurveyCustomerSmartWidgetConfigurationModel> {
    return this.httpClientService.put(
      `customers_smart_widget_configurations/${id}`,
      body,
      {});
  }

  // POST /api/v1/customers_smart_widget_configurations/
  //
  // PUT /api/v1/customers_smart_widget_configurations/<id>

  preInitializeWidgetCustomerConfData(customerConfData: CustomerDbModel): void {
    if (!this.customerDetail) {
      this.customerDetail = new CustomerDbModel();
    }
    this.customerDetail.email = customerConfData.email ? customerConfData.email : undefined;
    this.customerDetail.prename = customerConfData.prename ? customerConfData.prename : undefined;
    this.customerDetail.lastname = customerConfData.lastname ? customerConfData.lastname : undefined;
    this.customerDetail.mobile = customerConfData.mobile ? customerConfData.mobile : undefined;
    this.customerDetail.phone = customerConfData.phone ? customerConfData.phone : undefined;
    this.customerDetail.street = customerConfData.street ? customerConfData.street : undefined;
    this.customerDetail.zip = customerConfData.zip ? customerConfData.zip : undefined;
    this.customerDetail.comment = customerConfData.comment ? customerConfData.comment : undefined;
    this.customerDetail.city = customerConfData.city ? customerConfData.city : undefined;
    this.customerDetail.gender = customerConfData.salutation ? customerConfData.salutation : undefined;
    this.customerDetail.birthday = customerConfData.birthday ? customerConfData.birthday : undefined;
    this.customerDetail.notification_preference = customerConfData.notification_preference ? customerConfData.notification_preference : undefined;
    this.customerDetail.meeting_type_id = customerConfData.meeting_type_id ? customerConfData.meeting_type_id : undefined;
    this.customerDetail.company = customerConfData?.company ? customerConfData.company : undefined;
    this.customerDetail.title = customerConfData?.title ? customerConfData.title : undefined;
    this.customerDetail.country = customerConfData?.country ? customerConfData.country : undefined;
    this.customerDetail.policy = customerConfData?.policy ? customerConfData.policy : undefined;
  }

  processBlurCustomFieldSelectedValue(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
    LoggerService.log('Inside processCustomFieldSelectedValue ', widgetQuestion.type);
    switch (widgetQuestion.type) {
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TEXT:
        this.processTextCustomField(widgetQuestion, customerDetail, eventData);
        return;
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TEXT_AREA:
        this.processTextAreaCustomField(widgetQuestion, customerDetail, eventData);
        return;
    }
  }

  processCustomFieldSelectedValue(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
    LoggerService.log('Inside processCustomFieldSelectedValue ', widgetQuestion.type);
    LoggerService.log('Event data', eventData);
    switch (widgetQuestion.type) {
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TEXT:
        this.processTextCustomField(widgetQuestion, customerDetail, eventData);
        return;
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_TEXT_AREA:
        this.processTextAreaCustomField(widgetQuestion, customerDetail, eventData);
        return;
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT:
        this.processSelectCustomField(widgetQuestion, customerDetail, eventData);
        return;
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_CHECKBOX:
        this.processCheckboxCustomField(widgetQuestion, customerDetail, eventData);
        return;
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_FILE:
        this.processFileCustomField(widgetQuestion, customerDetail, eventData);
        return;
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO:
        this.processRadioCustomField(widgetQuestion, customerDetail, eventData);
        return;
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_DATE:
        this.processDateCustomField(widgetQuestion, customerDetail, eventData);
        return;
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_NUMBER_SELECT_CARD:
        this.processNumberSelectCustomField(widgetQuestion, customerDetail, eventData);
        return;
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_IMAGE_SELECT_CARD:
        this.processImageSelectCustomField(widgetQuestion, customerDetail, eventData);
        return;
      case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT_CARD:
        this.processSelectCardCustomField(widgetQuestion, customerDetail, eventData);
        return;
    }
  }

  processSelectCustomField(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
    const customerCustomFieldRequestBody: CustomerCustomFieldRequestBody = new CustomerCustomFieldRequestBody();
    const customFieldValues: CustomFieldValueRequestBody[] = [];
    customerCustomFieldRequestBody.customer_id = customerDetail.id as number;
    widgetQuestion.customerSelectedValue.forEach((value: any, index: number) => {
      if (value) {
        customFieldValues.push({
          custom_field_id: widgetQuestion.id,
          type: widgetQuestion.type,
          value: (Array.isArray(value) ? value : [value]),
          option_values: [],
          belongs_to_column: (index + 1),
          appointment_id: this.swRouteService.getReferenceAppointmentId,
          entity_name: (eventData?.entityNames ? eventData.entityNames[index] : null)
        });
      }
    });

    customerCustomFieldRequestBody.customFieldValues = customFieldValues;
    this.updateCustomerCustomFields(customerCustomFieldRequestBody);
  }

  processTextCustomField(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
    const customerCustomFieldRequestBody: CustomerCustomFieldRequestBody = new CustomerCustomFieldRequestBody();
    const customFieldValues: CustomFieldValueRequestBody[] = [];
    customerCustomFieldRequestBody.customer_id = customerDetail.id as number;

    widgetQuestion.customerSelectedValue.forEach((value: any, index: number) => {
      if (value) {
        customFieldValues.push({
          custom_field_id: widgetQuestion.id,
          type: widgetQuestion.type,
          value,
          option_values: [],
          belongs_to_column: (index + 1),
          appointment_id: this.swRouteService.getReferenceAppointmentId,
          entity_name: (eventData?.entityNames ? eventData.entityNames[index] : null)
        });
      }
    });

    customerCustomFieldRequestBody.customFieldValues = customFieldValues;
    this.updateCustomerCustomFields(customerCustomFieldRequestBody);
  }

  processTextAreaCustomField(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
    const customerCustomFieldRequestBody: CustomerCustomFieldRequestBody = new CustomerCustomFieldRequestBody();
    const customFieldValues: CustomFieldValueRequestBody[] = [];
    customerCustomFieldRequestBody.customer_id = customerDetail.id as number;

    widgetQuestion.customerSelectedValue.forEach((value: any, index: number) => {
      if (value) {
        customFieldValues.push({
          custom_field_id: widgetQuestion.id,
          type: widgetQuestion.type,
          value,
          option_values: [],
          belongs_to_column: (index + 1),
          appointment_id: this.swRouteService.getReferenceAppointmentId,
          entity_name: (eventData?.entityNames ? eventData.entityNames[index] : null)
        });
      }
    });

    customerCustomFieldRequestBody.customFieldValues = customFieldValues;
    this.updateCustomerCustomFields(customerCustomFieldRequestBody);
  }

  processCheckboxCustomField(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
    const customerCustomFieldRequestBody: CustomerCustomFieldRequestBody = new CustomerCustomFieldRequestBody();
    const customFieldValues: CustomFieldValueRequestBody[] = [];
    customerCustomFieldRequestBody.customer_id = customerDetail.id as number;

    widgetQuestion.customerSelectedValue.forEach((value: any, index: number) => {
      customFieldValues.push({
        custom_field_id: widgetQuestion.id,
        type: widgetQuestion.type,
        value: (value ? 'true' : 'false'),
        option_values: [],
        belongs_to_column: (index + 1),
        appointment_id: this.swRouteService.getReferenceAppointmentId,
        entity_name: (eventData?.entityNames ? eventData.entityNames[index] : null)
      });
    });

    customerCustomFieldRequestBody.customFieldValues = customFieldValues;
    this.updateCustomerCustomFields(customerCustomFieldRequestBody);
  }

  processFileCustomField(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
  }

  processRadioCustomField(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
    const customerCustomFieldRequestBody: CustomerCustomFieldRequestBody = new CustomerCustomFieldRequestBody();
    const customFieldValues: CustomFieldValueRequestBody[] = [];
    customerCustomFieldRequestBody.customer_id = customerDetail.id as number;

    widgetQuestion.customerSelectedValue.forEach((value: any, index: number) => {
      if (value) {
        customFieldValues.push({
          custom_field_id: widgetQuestion.id,
          type: widgetQuestion.type,
          value,
          option_values: [],
          belongs_to_column: (index + 1),
          appointment_id: this.swRouteService.getReferenceAppointmentId,
          entity_name: (eventData?.entityNames ? eventData.entityNames[index] : null)
        });
      }
    });

    customerCustomFieldRequestBody.customFieldValues = customFieldValues;
    this.updateCustomerCustomFields(customerCustomFieldRequestBody);
  }

  processDateCustomField(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
    const customerCustomFieldRequestBody: CustomerCustomFieldRequestBody = new CustomerCustomFieldRequestBody();
    const customFieldValues: CustomFieldValueRequestBody[] = [];
    customerCustomFieldRequestBody.customer_id = customerDetail.id as number;

    widgetQuestion.customerSelectedValue.forEach((value: any, index: number) => {
      if (value) {
        customFieldValues.push({
          custom_field_id: widgetQuestion.id,
          type: widgetQuestion.type,
          value,
          option_values: [],
          belongs_to_column: (index + 1),
          appointment_id: this.swRouteService.getReferenceAppointmentId,
          entity_name: (eventData?.entityNames ? eventData.entityNames[index] : null)
        });
      }
    });

    customerCustomFieldRequestBody.customFieldValues = customFieldValues;
    this.updateCustomerCustomFields(customerCustomFieldRequestBody);
  }

  processNumberSelectCustomField(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
    const customerCustomFieldRequestBody: CustomerCustomFieldRequestBody = new CustomerCustomFieldRequestBody();
    const customFieldValues: CustomFieldValueRequestBody[] = [];
    customerCustomFieldRequestBody.customer_id = customerDetail.id as number;

    widgetQuestion.customerSelectedValue.forEach((value: any, index: number) => {
      if (value) {
        customFieldValues.push({
          custom_field_id: widgetQuestion.id,
          value: value.map((item: any) => item.custom_field_option_id),
          option_values: value,
          type: widgetQuestion.type,
          belongs_to_column: (index + 1),
          appointment_id: this.swRouteService.getReferenceAppointmentId,
          entity_name: (eventData?.entityNames ? eventData.entityNames[index] : null)
        });
      }
    });

    customerCustomFieldRequestBody.customFieldValues = customFieldValues;
    this.updateCustomerCustomFields(customerCustomFieldRequestBody);
  }

  processImageSelectCustomField(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
    const customerCustomFieldRequestBody: CustomerCustomFieldRequestBody = new CustomerCustomFieldRequestBody();
    const customFieldValues: CustomFieldValueRequestBody[] = [];
    customerCustomFieldRequestBody.customer_id = customerDetail.id as number;

    widgetQuestion.customerSelectedValue.forEach((value: any, index: number) => {
      if (value) {
        customFieldValues.push({
          custom_field_id: widgetQuestion.id,
          type: widgetQuestion.type,
          value,
          option_values: [],
          belongs_to_column: (index + 1),
          appointment_id: this.swRouteService.getReferenceAppointmentId,
          entity_name: (eventData?.entityNames ? eventData.entityNames[index] : null)
        });
      }
    });

    customerCustomFieldRequestBody.customFieldValues = customFieldValues;
    this.updateCustomerCustomFields(customerCustomFieldRequestBody);
  }

  processSelectCardCustomField(
    widgetQuestion: CustomFieldModel,
    customerDetail: CustomerDbModel,
    eventData: { [key: string]: any }
  ): void {
    const customerCustomFieldRequestBody: CustomerCustomFieldRequestBody = new CustomerCustomFieldRequestBody();
    const customFieldValues: CustomFieldValueRequestBody[] = [];
    customerCustomFieldRequestBody.customer_id = customerDetail.id as number;

    widgetQuestion.customerSelectedValue.forEach((value: any, index: number) => {
      if (value) {
        customFieldValues.push({
          custom_field_id: widgetQuestion.id,
          type: widgetQuestion.type,
          value,
          option_values: [],
          belongs_to_column: (index + 1),
          appointment_id: this.swRouteService.getReferenceAppointmentId,
          entity_name: (eventData?.entityNames ? eventData.entityNames[index] : null)
        });
      }
    });

    customerCustomFieldRequestBody.customFieldValues = customFieldValues;
    this.updateCustomerCustomFields(customerCustomFieldRequestBody);
  }

  updateCustomerCustomFields(customerCustomFieldRequestBody: CustomerCustomFieldRequestBody): void {
    LoggerService.log('updateCustomerCustomFields ', customerCustomFieldRequestBody);
    this.updateCustomerCustomField(customerCustomFieldRequestBody).subscribe({
      next: (result: CustomerCustomFieldDbModel[]) => {
        this.updateCustomersSelectedValuesLocally(result);
        LoggerService.log('Inside updateCustomerCustomFields success');
      },
      error: (error: Error) => {
        LoggerService.error(error);
        LoggerService.log('Inside updateCustomerCustomFields failed');
      }
    });
  }

  handleHiddenBookingQuestions(
    hiddenBookingQuestions: CustomFieldModel[],
    customerDetail: CustomerDbModel,
  ): void {
    if (hiddenBookingQuestions?.length > 0 && customerDetail) {
      hiddenBookingQuestions = hiddenBookingQuestions
        .filter((v: CustomFieldModel, i: number, a: CustomFieldModel[]) => a.findIndex(t => (t.id === v.id)) === i);
      const queryParamCustomFieldValues: { id?: number, value?: any, ignore?: boolean }[] = this.swRouteService.getCustomFieldValues();
      if (queryParamCustomFieldValues?.length > 0) {
        for (const hiddenCustomField of hiddenBookingQuestions) {
          const foundedQueryParamCustomField: { id?: number, value?: any, ignore?: boolean } = queryParamCustomFieldValues.find(
            (item: { id?: number, value?: any, ignore?: boolean }) => {
              return item.id === hiddenCustomField.id;
            }
          );

          if (!foundedQueryParamCustomField?.ignore && foundedQueryParamCustomField?.id) {
            LoggerService.log('Loading custom field data from queryarams ');
            this.prefillCustomFieldValueFromQueryParams(hiddenCustomField, foundedQueryParamCustomField.value);
          }

          if (hiddenCustomField?.customerSelectedValue !== undefined) {
            this.processCustomFieldSelectedValue(
              hiddenCustomField,
              customerDetail,
              null
            );
          }
        }
      } else {
        for (const hiddenCustomField of hiddenBookingQuestions) {
          if (hiddenCustomField?.customerSelectedValue !== undefined) {
            this.processCustomFieldSelectedValue(
              hiddenCustomField,
              customerDetail,
              null
            );
          }
        }
      }
    }
  }

  prefillCustomFieldValueFromQueryParams(customField: CustomFieldModel, value: any): void {
    LoggerService.log('Inside booking service prefillCustomFieldValueFromQueryParams value is ', value, 'customField', customField);
    if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT) {
      if (customField.is_multiple_select === 1) {
        if (Array.isArray(value)) {
          customField.customerSelectedValue = [value.map(id => Number(id))];
        } else {
          customField.customerSelectedValue = [[Number(value)]];
        }
      } else {
        customField.customerSelectedValue = (value) ? [[Number(value)]] : undefined;
      }
    } else if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_IMAGE_SELECT_CARD || customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT_CARD) {
      if (customField.is_multiple_select === 1) {
        if (Array.isArray(value)) {
          customField.customerSelectedValue = [value.map(id => Number(id))];
        } else {
          customField.customerSelectedValue = [[Number(value)]];
        }
      } else {
        customField.customerSelectedValue = (value) ? [[Number(value)]] : [];
      }
    } else if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_FILE) {
      // TODO currently file custom field is not supporting query params
    } else if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO) {
      customField.customerSelectedValue = [Number(value)];
      if (customField.custom_field_options?.length > 0) {
        const options = (Array.isArray(customField.custom_field_options[0]) ? customField.custom_field_options[0] : customField.custom_field_options);
        const foundedCFOption: CustomFieldOptionModel = options.find((item: CustomFieldOptionModel) => {
          return item.id === Number(value);
        });
        if (foundedCFOption) {
          // this.onRadioValueChanged(foundedCFOption, false);
        }
      }
    } else if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_DATE) {
      customField.customerSelectedValue = [value];
      // this.setSelectedDate(value);
    } else if (customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_CHECKBOX) {
      if (value === true || value === 'true') {
        customField.customerSelectedValue = [true];
      } else {
        customField.customerSelectedValue = [false];
      }
    } else {
      customField.customerSelectedValue = [value];
    }

    LoggerService.log('Customer selected value this.customField.customerSelectedValue ', customField.customerSelectedValue);
  }

  initializePartnerData(partnerUUID: string, tokenType: TokenType): Observable<PartnerDbModel> {
    const body: { partner_uuid: string, token_type: TokenType } = { partner_uuid: partnerUUID, token_type: tokenType };
    return this.httpClientService.post('partners/information', body, {
      context: new HttpContext().set(IS_CALENSO_AUTH_REQUIRED, true)
    });
  }

  updateCustomersSelectedValuesLocally(customFields: CustomerCustomFieldDbModel[]): void {
    const deletedFielsIds = customFields.map(customField => Number(customField.custom_field_id));
    const filteredCustomerCustomFields = this.customerDetail.custom_fields.filter(customField => !deletedFielsIds.includes(customField.custom_field_id));
    this.customerDetail.custom_fields = [...filteredCustomerCustomFields, ...customFields];
    LoggerService.log('[updateCustomersSelectedValuesLocally]', this.customerDetail.custom_fields);
  }
}
