import { JsonPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component, ElementRef, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CUSTOM_FIELD_TYPES, MIME_TYPES } from '@constants/app.constants';
import { DATE_FORMAT, DATE_FORMATS } from '@constants/date.constants';
import { environment } from '@environments/environment';
import { CustomFieldModel, CustomFieldOptionModel } from '@models/custom-field.model';
import { CustomCustomFieldFileDbModel, CustomerCustomFieldDbModel } from '@models/customer.model';
import { CurrentSelectedAppointmentState } from '@models/selected-booking-data.model';
import { WidgetConfModel } from '@models/widget-conf.model';
import { WorkerDbModel } from '@models/worker-db.model';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioMeetingTemplatePipe } from '@pipes/calio-meeting-template.pipe';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { IsEmptyStringPipe } from '@pipes/is-empty-string.pipe';
import { BookingService } from '@services/feature/booking.service';
import { CustomFieldsService } from '@services/feature/custom-fields.service';
import { SurveyService } from '@services/feature/survey.service';
import { WidgetService } from '@services/feature/widget.service';
import { WorkerService } from '@services/feature/worker.service';
import { DateUtilService } from '@services/utils/date-util.service';
import { HelperService } from '@services/utils/helper.service';
import { LoggerService } from '@services/utils/logger.service';
import { SwRouteService } from '@services/utils/sw-route.service';
import { InfoCardComponent } from '@ui-lib/cards/info-card/info-card.component';
import { ImageModalComponent } from '@ui-lib/modals/image-modal/image-modal.component';
import { YoutubeModalComponent } from '@ui-lib/modals/youtube-modal/youtube-modal.component';
import { CswLinkComponent } from '@ui-lib/typography/csw-link/csw-link.component';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';

@Component({
  selector: 'app-booking-question',
  templateUrl: './booking-question.component.html',
  styleUrls: ['./booking-question.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  }, {
    provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS
  }],
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, NgSelectModule, FormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, InfoCardComponent, MatCheckboxModule, MatIconModule, CswLinkComponent, CswTextComponent, NgStyle, JsonPipe, TranslateModule, CalioSafePipe, CalioTranslationPipe, IsEmptyStringPipe, CalioMeetingTemplatePipe]
})
export class BookingQuestionComponent implements OnInit {

  readonly deployUrl = environment.deployUrl;
  readonly customFieldConst = CUSTOM_FIELD_TYPES;
  readonly imagePath = environment.hostName;
  readonly workerAvatarBaseUrl = environment.workerAvatarBaseUrl;
  readonly dateFormat = DATE_FORMAT;

  @Input() customerDataIsNotMandatoryToUploadFiles = true;
  @Input() isNextButtonPressed = false;
  @Input() deactivateField = false;
  @Input() prefix: string;
  @Input() customField: CustomFieldModel;
  @Input() selectedCustomFieldValues: {
    custom_field_id: number,
    type: string,
    value?: any, file_ids?: any
  }[] = [];
  @Input() textContainerStyleConfig: {
    marginTop?: string,
    marginBottom?: string,
  } = {};
  @Input() titleStyleConfig: {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
  } = {};
  @Input() labelStyleConfig: {
    color?: string,
    fontSize?: string,
    fontWeight?: string,
  } = {};
  @Input() parentField: CustomFieldModel = null;
  @Input() grandParentField: CustomFieldModel = null;
  @Input() surveyGlobalStep: number;
  @Input() customEventIndex: number = null;

  @Output() radioButtonChangeEvent = new EventEmitter<{
    customField: CustomFieldModel,
    customFieldOption: CustomFieldOptionModel,
    oldCustomFieldDependencyIds: number[],
    removeOldCustomFieldDependencyIds: boolean,
    entityIndex?: number
  }>();

  @Output() changeCustomFieldSelectedValueEvent = new EventEmitter<{ [key: string]: any }>();
  @Output() blurCustomFieldSelectedValueEvent = new EventEmitter<{ [key: string]: any }>();

  selectedFiles: string[] = [];
  lang: string;
  selectedDate: Date[] = [];
  isEditAllowed = true;
  youtubeInfoUrlDynamicElements: any[] = [];
  queryParamCustomFieldValues: { id?: number, value?: any, ignore?: boolean }[] = [];
  columnRangeCount = 1;
  columnRangeArray: number[] = [];
  imageSelectEntitySelector = 0;
  validImageSelect = true;
  endOfProcessMessageObj: { [key: string]: any } = {}
  widgetConf: WidgetConfModel;
  waitForFileUpload = {};
  workers: WorkerDbModel[] = [];
  egalWerShowSetting = true;
  maxFileSize = 1048576 * 5; // 5MB

  constructor(
    private translateService: TranslateService,
    private dateUtilService: DateUtilService,
    public widgetService: WidgetService,
    private customFieldsService: CustomFieldsService,
    private surveyService: SurveyService,
    private swRouteService: SwRouteService,
    private matDialog: MatDialog,
    private elementRef: ElementRef,
    public helperService: HelperService,
    private workerService: WorkerService,
    public bookingService: BookingService
  ) {
    this.widgetConf = this.widgetService.widgetConf
    this.lang = this.translateService.getDefaultLang();
    this.translateService.onLangChange.subscribe(language => this.lang = language.lang);
    this.queryParamCustomFieldValues = this.swRouteService.getCustomFieldValues();
  }

  ngOnInit(): void {
    this.egalWerShowSetting = !!Number(this.bookingService.partnerData?.egal_wer_show);

    this.columnRangeCount = this.parentField?.generate_entity_count ? this.parentField.generate_entity_count : 1;
    this.columnRangeArray = this.helperService.createArrayRange(this.columnRangeCount);
    this.endOfProcessMessageObj = {
      end_of_process_message: this.widgetService.widgetConf.end_of_process_message,
      is_multi_language: this.widgetService.widgetConf.is_multi_language,
      _translations: this.widgetService.widgetConf._translations
    }

    this.isEditAllowed = (this.customField.is_read_only === 0);

    if (!this.customField.customerSelectedValue) {
      this.customFieldsService.prefillCustomFieldSelectedValue(this.customField, []);
    }

    if (!this.customField.customerSelectedValue) {
      this.customField.customerSelectedValue = [];
    }

    if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_FILE) {
      this.updateMimeTypes();
      if (!this.customField.customerSelectedInputValue) { this.customField.customerSelectedInputValue = []; }
      if (!this.customField.customerSelectedFileValue) { this.customField.customerSelectedFileValue = []; }
    }

    const count = (this.parentField && this.parentField?.generate_entity_count ? this.parentField?.generate_entity_count : 1);
    for (let index = 0; index < count; index++) {
      if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_FILE) {
        this.customField.customerSelectedInputValue[index] = [];
        this.customField.customerSelectedFileValue[index] = [];
        this.customField.customerSelectedValue[index] = [];
        this.waitForFileUpload[index] = false;
      } else {
        this.customField.customerSelectedValue[index] = (this.customField.customerSelectedValue[index]
          ? this.customField.customerSelectedValue[index]
          : null);
      }
    }

    let foundedQueryParamCustomField: { id?: number, value?: any, ignore?: boolean } = {};

    if (this.queryParamCustomFieldValues?.length > 0) {
      foundedQueryParamCustomField = this.queryParamCustomFieldValues.find(
        (item: { id?: number, value?: any, ignore?: boolean }) => {
          return item.id === this.customField.id;
        }
      );
    }

    if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_NUMBER_SELECT_CARD) {
      this.setDefaultNumberCard();
    } else if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_WORKER_SELECT) {
      this.getWorkers();
    }

    if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_DATE) {
      this.customField.customerSelectedValue.forEach((item: any, index: number) => {
        if (item) {
          this.setSelectedDate(item, index);
        }
      });
    }

    if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO) {
      if (this.customField.customerSelectedValue) {
        if (this.customField.custom_field_options?.length > 0) {
          const foundedCFOption: CustomFieldOptionModel = this.customField.custom_field_options.find((item: CustomFieldOptionModel) => {
            return item.id === this.customField.customerSelectedValue;
          });
          if (foundedCFOption) {
            this.onRadioValueChanged(foundedCFOption, false);
          }
        }
      }
    }

    if ([
      CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO,
      CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT_CARD,
      CUSTOM_FIELD_TYPES.CUSTOM_FIELD_IMAGE_SELECT_CARD
    ].includes(this.customField.type)) {
      const optionsCopy = JSON.parse(JSON.stringify(this.customField.custom_field_options));
      const innerCount = (this.parentField && this.parentField?.generate_entity_count ? this.parentField?.generate_entity_count : 1);
      this.customField.custom_field_options = [];
      for (let index = 0; index < innerCount; index++) {
        this.customField.custom_field_options[index] = JSON.parse(JSON.stringify(optionsCopy));
      }
    }

    LoggerService.log('this.selectedCustomFieldValues ', this.selectedCustomFieldValues);
    if (!foundedQueryParamCustomField?.ignore && foundedQueryParamCustomField?.id) {
      LoggerService.log('Loading custom field data from queryarams ');
      this.prefillCustomFieldValueFromQueryParams(foundedQueryParamCustomField.value);
    } else if (this.selectedCustomFieldValues?.length > 0) {
      LoggerService.log('Loading data from selectedCustomFieldValues ');
      this.prefillCustomFieldValue();
    }

    this.handleAutoShowDependencies();
    this.loadOptionDependentQuestions();
  }

  getWorkers(): void {
    this.customField.isOptionsAreLoading = true;
    this.workerService.getWorkers().subscribe({
      next: (workers => {
        workers = workers.filter(worker => worker?.bookable === 1 && worker?.store?.only_accessible_through_deeplink === 0);
        workers = this.helperService.sortBy(workers, 'resource_name');
        this.workers = workers;
        this.customField.isOptionsAreLoading = false;
      }),
      error: ((error: HttpErrorResponse) => {
        this.customField.isOptionsAreLoading = false;
        LoggerService.error(error);
      })
    });
  }

  handleAutoShowDependencies(): void {
    if (
      this.customField.is_multiple_select === 0 &&
      this.customField.is_entity_generator &&
      this.customField.customerSelectedValue && this.customField.customerSelectedValue?.length > 0
    ) {
      const foundOption = this.customField.custom_field_options[0]
        .find((option: CustomFieldOptionModel) => option.id === Number(this.customField.customerSelectedValue[0]));

      switch ((foundOption ? this.customField.type : null)) {
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT:
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_IMAGE_SELECT_CARD:
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT_CARD:
          if (foundOption) {
            this.onCardImageCustomField(null, foundOption, 0);
          }
          return;
        case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO:
          if (foundOption) {
            this.onRadioValueChanged(foundOption, true, 0);
          }
          return;
      }
    }
  }

  loadOptionDependentQuestions(): void {
    if (
      this.customField.type !== this.customFieldConst.CUSTOM_FIELD_WORKER_SELECT &&
      this.customField.is_entity_generator === 0 &&
      this.customField?.custom_field_options && this.customField?.custom_field_options?.length > 0 &&
      this.customField.customerSelectedValue && this.customField.customerSelectedValue?.length > 0
    ) {
      const options = Array.isArray(this.customField.custom_field_options[0]) ? this.customField.custom_field_options[0] : this.customField.custom_field_options;
      const hasOptionDependencies = options.filter(option => option.dependencies !== null);
      if (hasOptionDependencies.length > 0) {
        const entityIndex = this.customField.customerSelectedValue.findIndex((element: any) => element!)
        if (entityIndex >= 0) {
          const ids = this.customField.customerSelectedValue[entityIndex];
          if (ids) {
            switch (this.customField.type) {
              case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT:
                this.onSelectChange(ids, entityIndex);
                break;
              case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_IMAGE_SELECT_CARD:
              case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT_CARD:
                if (!this.customField.is_multiple_select) {
                  const foundOption = hasOptionDependencies.find(option => Number(option.id) === Number(ids[0]));
                  this.onCardImageCustomField(null, foundOption, 0);
                }
                break;
              case CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO:
                // this.onRadioValueChanged(foundOption, true, 0);
                break;
            }
          }
        }
      }

    } else if (
      this.customField.type === this.customFieldConst.CUSTOM_FIELD_WORKER_SELECT &&
      this.customField.customerSelectedValue && this.customField.customerSelectedValue?.length > 0
    ) {
      this.customField.customerSelectedValue = [];
      this.bookingService.setAppointmentStateInLocalStorage(
        new CurrentSelectedAppointmentState()
      );
      // this.radioButtonChangeEvent.emit({
      //   customField: this.customField,
      //   customFieldOption: null,
      //   oldCustomFieldDependencyIds: [],
      //   removeOldCustomFieldDependencyIds: true,
      // });
    }
  }

  bindYoutubeInfoUrlClickHandler(): void {
    setTimeout(() => {
      this.youtubeInfoUrlDynamicElements = this.elementRef.nativeElement.querySelectorAll('.select-option-youtube-element');
      if (this.youtubeInfoUrlDynamicElements.length > 0) {
        this.youtubeInfoUrlDynamicElements.forEach((element: any) => {
          element.addEventListener('click', () => {
            if (element.dataset?.option) {
              const custom_field_option = JSON.parse(element.dataset.option) as CustomFieldOptionModel;
              this.openYoutubeVideo(custom_field_option, null);
            }
          });
        });
      }
    }, 100);
  }

  unbindYoutubeInfoUrlClickHandler(): void {
    if (this.youtubeInfoUrlDynamicElements.length > 0) {
      this.youtubeInfoUrlDynamicElements.forEach((element: any) => {
        element.removeEventListener('click', null);
      });
    }
  }

  onSelectChange(event: any, entityIndex = 0): void {
    if (!this.isEditAllowed || this.deactivateField) {
      LoggerService.log('Editing is disabled');
      return;
    }

    LoggerService.log('select event is', event, this.customField);

    if (!this.customField.customerSelectedValue[entityIndex] || this.customField.customerSelectedValue[entityIndex]?.length === 0) {
      this.customField.customerSelectedValue[entityIndex] = [];
    }

    let oldCustomFieldDependencyIds: number[] = [];
    if (this.customField?.customFieldDependencies?.length > 0) {
      oldCustomFieldDependencyIds = this.customField.customFieldDependencies.map(i => i.id);
    }

    const customFieldOption = this.customField.custom_field_options.filter((option: CustomFieldOptionModel) => {
      return Array.isArray(event) ? event.includes(option.id) : [event].includes(option.id);
    });
    this.customField.customFieldDependencies = [];
    const checkForOptionsDependentQuestions = customFieldOption.filter((field: CustomFieldOptionModel) => field.dependencies !== null);

    this.onChangeCustomFieldSelectedValue({
      entityIndex,
      checkForOptionsDependentQuestions: (checkForOptionsDependentQuestions.length > 0)
    });

    this.customField.showEndOfProcess = (customFieldOption?.is_end_of_process === 1);
    this.widgetService.endOfProcessSurvey = {
      step: this.surveyGlobalStep,
      status: this.customField.showEndOfProcess
    };

    if (
      this.customField.showEndOfProcess === false &&
      this.customField?.is_entity_generator === 1 &&
      this.customField?.is_multiple_select === 0
    ) {
      this.handleEntityGenerator(customFieldOption);

      this.radioButtonChangeEvent.emit({
        customField: this.customField,
        customFieldOption,
        oldCustomFieldDependencyIds,
        removeOldCustomFieldDependencyIds: true,
        entityIndex
      });
    } else if (this.customField.showEndOfProcess === false) {
      this.customField.generate_entity_count = 0;
      this.radioButtonChangeEvent.emit({
        customField: this.customField,
        customFieldOption,
        oldCustomFieldDependencyIds,
        removeOldCustomFieldDependencyIds: true,
        entityIndex
      });
    }

    if (this.customField.type === this.customFieldConst.CUSTOM_FIELD_WORKER_SELECT) {
      let foundWorker: WorkerDbModel;
      if (event === '0') {
        const anybodyWorkerIndex = Math.floor(Math.random() * this.workers.length);
        foundWorker = this.workers[anybodyWorkerIndex];
      } else {
        foundWorker = this.workers.find(worker => worker.id === event);
      }
      this.preSelectWorkerAndStore(foundWorker);
    }
  }

  onCheckBoxFieldChange(value: boolean): void {
    LoggerService.log('on checkbox field change ', value, this.customField);

    if (!this.customField.showEndOfProcess) {
      this.radioButtonChangeEvent.emit({
        customField: this.customField,
        customFieldOption: null,
        oldCustomFieldDependencyIds: [],
        removeOldCustomFieldDependencyIds: true,
      });
    }

    this.onChangeCustomFieldSelectedValue();
  }

  onRadioValueChanged(item: CustomFieldOptionModel, removeOldCustomFieldDependencyIds: boolean, entityIndex = 0): void {
    LoggerService.log('onRadioValueChanged item ', item);
    LoggerService.log('this.customField.customFieldDependencies ', Object.assign([], this.customField.customFieldDependencies));
    if (!this.isEditAllowed) {
      LoggerService.warn('Radio button is disabled');
      this.customField.customerSelectedValue[entityIndex] = undefined;
      return;
    }

    let oldCustomFieldDependencyIds: number[] = [];
    if (this.customField?.customFieldDependencies?.length > 0) {
      oldCustomFieldDependencyIds = this.customField.customFieldDependencies.map(i => i.id);
    }
    this.customField.customerSelectedValue[entityIndex] = item.id;
    this.customField.customFieldDependencies = [];
    this.onChangeCustomFieldSelectedValue();

    this.customField.showEndOfProcess = (item?.is_end_of_process === 1);
    this.widgetService.endOfProcessSurvey = {
      step: this.surveyGlobalStep,
      status: this.customField.showEndOfProcess
    };

    if (this.customField.showEndOfProcess === false) {
      this.radioButtonChangeEvent.emit({
        customField: this.customField,
        customFieldOption: item,
        oldCustomFieldDependencyIds,
        removeOldCustomFieldDependencyIds,
        entityIndex
      });
    }
  }

  onChangeDate(event: MatDatepickerInputEvent<any>, entityIndex: number): void {
    const format = (this.customField.format ? this.customField.format.toUpperCase() : this.dateFormat);
    this.customField.customerSelectedValue[entityIndex] = this.dateUtilService.getFormattedDateByFormat(event.value, format);
    this.onChangeCustomFieldSelectedValue();
  }

  prefillCustomFieldValue(): void {
    const foundedValue: {
      custom_field_id: number,
      type: string,
      value?: any, file_ids?: any, option_values?: {
        custom_field_option_id: number,
        custom_field_option_value: number
      }[]
    } = this.selectedCustomFieldValues.find((item: {
      custom_field_id: number,
      type: string,
      value?: any, file_ids?: any, option_values?: {
        custom_field_option_id: number,
        custom_field_option_value: number
      }[]
    }) => {
      return item.custom_field_id === this.customField.id;
    });

    if (!foundedValue) {
      return;
    }

    if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT || this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_WORKER_SELECT) {
      if (this.customField.is_multiple_select === 1) {
        this.customField.customerSelectedValue[0] = foundedValue.value;
      } else {
        this.customField.customerSelectedValue[0] = (foundedValue.value && foundedValue.value[0]) ? foundedValue.value[0] : undefined;
      }
    } else if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_FILE) {
      this.customField.customerSelectedValue[0] = foundedValue.file_ids;
      this.getFilesByFileIds(foundedValue.file_ids);
    } else if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO) {
      this.customField.customerSelectedValue[0] = Number(foundedValue.value);
      if (this.customField.custom_field_options?.length > 0) {
        const foundedCFOption: CustomFieldOptionModel = this.customField.custom_field_options.find((item: CustomFieldOptionModel) => {
          return item.id === this.customField.customerSelectedValue;
        });
        if (foundedCFOption) {
          this.onRadioValueChanged(foundedCFOption, false);
        }
      }
    } else if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_DATE) {
      this.customField.customerSelectedValue[0] = foundedValue.value;
      this.setSelectedDate(foundedValue.value);
    } else if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_CHECKBOX ) {
      if (foundedValue.value === true || foundedValue.value === 'true') {
        this.customField.customerSelectedValue[0] = true;
      } else {
        this.customField.customerSelectedValue[0] = false;
      }
    } else if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_NUMBER_SELECT_CARD) {
      if (!this.customField?.customerSelectedValue) {
        this.customField.customerSelectedValue[0] = [];
      }
      if (foundedValue?.option_values?.length > 0) {
        this.customField.customerSelectedValue[0] = foundedValue?.option_values;
      }

      for (const option of this.customField.custom_field_options[0]) {
        const foundedItem: {
          custom_field_option_id: number,
          custom_field_option_value: number
        } = this.customField.customerSelectedValue[0].find((item: {
          custom_field_option_id: number,
          custom_field_option_value: number
        }) => {
          return option.id === Number(item.custom_field_option_id);
        });
        LoggerService.log('foundedItem ', foundedItem);
        if (foundedItem) {
          option.option_value = foundedItem.custom_field_option_value;
          /*widgetQuestion.customerSelectedValue.push({
            custom_field_option_id: option.id,
            custom_field_option_value: Number(option.option_value)
          });*/
        } else {
          option.option_value = option.number_default;
        }
      }
    } else {
      this.customField.customerSelectedValue[0] = foundedValue.value;
    }
  }

  prefillCustomFieldValueFromQueryParams(value: any): void {
    if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT) {
      if (this.customField.is_multiple_select === 1) {
        if (Array.isArray(value)) {
          this.customField.customerSelectedValue[0] = value.map(id => Number(id));
        } else {
          this.customField.customerSelectedValue[0] = [Number(value)];
        }
      } else {
        this.customField.customerSelectedValue[0] = (value) ? Number(value) : undefined;
      }
    } else if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_IMAGE_SELECT_CARD || this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_SELECT_CARD) {
      if (this.customField.is_multiple_select === 1) {
        if (Array.isArray(value)) {
          this.customField.customerSelectedValue[0] = value.map(id => Number(id));
        } else {
          this.customField.customerSelectedValue[0] = [Number(value)];
        }
      } else {
        this.customField.customerSelectedValue[0] = (value) ? [Number(value)] : [];
      }
    } else if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_FILE) {
      // TODO currently file custom field is not supporting query params
    } else if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_RADIO) {
      this.customField.customerSelectedValue[0] = Number(value);
      if (this.customField.custom_field_options[0]?.length > 0) {
        const foundedCFOption: CustomFieldOptionModel = this.customField.custom_field_options[0].find((item: CustomFieldOptionModel) => {
          return item.id === this.customField.customerSelectedValue;
        });
        if (foundedCFOption) {
          this.onRadioValueChanged(foundedCFOption, false);
        }
      }
    } else if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_DATE) {
      this.customField.customerSelectedValue[0] = value;
      this.setSelectedDate(value);
    } else if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_CHECKBOX) {
      if (value === true || value === 'true') {
        this.customField.customerSelectedValue[0] = true;
      } else {
        this.customField.customerSelectedValue[0] = false;
      }
    } else {
      this.customField.customerSelectedValue[0] = value;
    }

    LoggerService.log('Customer selected value this.customField.customerSelectedValue ', this.customField.customerSelectedValue);
  }

  setSelectedDate(date: string, entityIndex = 0): void {
    LoggerService.log('Inside setSelectedDate this.customField.format ', this.customField.format);
    if (this.customField.format) {
      this.selectedDate[entityIndex] = this.dateUtilService.getDateFromString(date, this.customField.format.toUpperCase());
    } else {
      this.selectedDate[entityIndex] = this.dateUtilService.getDateFromString(date, this.dateFormat);
    }
  }

  setDefaultNumberCard(): void {
    for (const option of this.customField.custom_field_options) {
      option.option_value = option.number_default;
    }
    const optionsCopy = JSON.parse(JSON.stringify(this.customField.custom_field_options));
    if (this.parentField && this.parentField?.generate_entity_count) {
      this.customField.custom_field_options = [];
      for (let index = 0; index < this.parentField.generate_entity_count; index++) {
        this.customField.custom_field_options[index] = JSON.parse(JSON.stringify(optionsCopy));
      }
    } else {
      this.customField.custom_field_options = [JSON.parse(JSON.stringify(optionsCopy))];
    }

    if (this.customField.customerSelectedValue?.length > 0) {
      for (const [index, values] of this.customField.custom_field_options.entries()) {
        for (const value of values) {
          LoggerService.log('[value]', value);
          let foundOption = this.customField.customerSelectedValue[index];
          if (foundOption) {
            foundOption = foundOption.find((option: any) => option.custom_field_option_id === value.id);
            if (foundOption) {
              value.option_value = foundOption.custom_field_option_value;
            }
          }
        }
      }
    }
  }

  onCardImageCustomField(event: MatCheckboxChange, option: CustomFieldOptionModel, entityIndex = 0): void {
    if (!this.isEditAllowed || this.deactivateField) {
      LoggerService.log('Editing is disabled');
      return;
    }

    LoggerService.log(
      'Inside onCardImageCustomField this.customField.customerSelectedValue',
      this.customField.customerSelectedValue[entityIndex]
    );

    if (!this.customField.customerSelectedValue[entityIndex] || this.customField.customerSelectedValue[entityIndex]?.length === 0) {
      this.customField.customerSelectedValue[entityIndex] = [];
    }

    if (!Array.isArray(this.customField.customerSelectedValue[entityIndex])) {
      if (this.customField.customerSelectedValue[entityIndex].indexOf(',') > -1) {
        this.customField.customerSelectedValue[entityIndex] = this.customField.customerSelectedValue[entityIndex]
          .split(',')
          .map((i: any) => Number(i));
      } else {
        this.customField.customerSelectedValue[entityIndex] = [Number(this.customField.customerSelectedValue[entityIndex])];
      }
    }

    if (this.customField.is_multiple_select === 1) {
      if (this.customField.customerSelectedValue[entityIndex]?.indexOf(option.id) > -1) {
        this.customField.customerSelectedValue[entityIndex] = this.customField.customerSelectedValue[entityIndex].filter((item: number) => {
          return item !== option.id;
        });
        option.customerSelectedValue = false;
      } else {
        this.customField.customerSelectedValue[entityIndex].push(option.id);
        option.customerSelectedValue = true;
      }
    } else {
      this.customField.customerSelectedValue[entityIndex] = [option.id];
      option.customerSelectedValue = true;
    }

    let oldCustomFieldDependencyIds: number[] = [];
    if (this.customField?.customFieldDependencies?.length > 0) {
      oldCustomFieldDependencyIds = this.customField.customFieldDependencies.map(i => i.id);
    }
    this.customField.customFieldDependencies = [];
    this.onChangeCustomFieldSelectedValue(option);

    this.customField.showEndOfProcess = (option?.is_end_of_process === 1);
    this.widgetService.endOfProcessSurvey = {
      step: this.surveyGlobalStep,
      status: this.customField.showEndOfProcess
    };

    if (
      this.customField.showEndOfProcess === false &&
      this.customField?.is_entity_generator === 1 &&
      this.customField?.is_multiple_select === 0
    ) {
      this.handleEntityGenerator(option);

      this.radioButtonChangeEvent.emit({
        customField: this.customField,
        customFieldOption: option,
        oldCustomFieldDependencyIds,
        removeOldCustomFieldDependencyIds: true
      });
    } else if (this.customField.showEndOfProcess === false) {
      this.customField.generate_entity_count = 0;
      this.radioButtonChangeEvent.emit({
        customField: this.customField,
        customFieldOption: option,
        oldCustomFieldDependencyIds,
        removeOldCustomFieldDependencyIds: true
      });
    }

    if (this.customField.required === 1 && this.customField.is_read_only === 0) {
      const hasNullValues = this.customField.customerSelectedValue.find((answer: any) => answer === null);
      this.validImageSelect = (hasNullValues === null) ? false : true;
    }
  }

  addNumberCardValue(option: CustomFieldOptionModel, entityIndex = 0): void {
    if (!this.isEditAllowed || this.deactivateField) {
      LoggerService.log('Editing is disabled');
      return;
    }
    LoggerService.log('option ', option);
    if (Number(option?.option_value) < option.number_max) {
      option.option_value = Number(option?.option_value) + 1;
      if (!this.customField.customerSelectedValue[entityIndex] || this.customField.customerSelectedValue[entityIndex]?.length === 0) {
        this.customField.customerSelectedValue[entityIndex] = [];
      }
      if (this.customField.customerSelectedValue[entityIndex].length === 0) {
        this.customField.customerSelectedValue[entityIndex].push({
          custom_field_option_id: option.id,
          custom_field_option_value: option.option_value
        });
      } else {
        const foundedItem: { custom_field_option_id: number, custom_field_option_value: number } =
          this.customField.customerSelectedValue[entityIndex]
            .find((item: { custom_field_option_id: number, custom_field_option_value: number }) => {
              return item.custom_field_option_id === option.id;
            });
        if (foundedItem) {
          for (const optionValue of this.customField.customerSelectedValue[entityIndex]) {
            if (optionValue.custom_field_option_id === option.id) {
              optionValue.custom_field_option_value = option.option_value;
            }
          }
        } else {
          this.customField.customerSelectedValue[entityIndex].push({
            custom_field_option_id: option.id,
            custom_field_option_value: option.option_value
          });
        }
      }
    }
    this.onChangeCustomFieldSelectedValue(option);
  }

  reduceNumberCardValue(option: CustomFieldOptionModel, entityIndex = 0): void {
    if (!this.isEditAllowed || this.deactivateField) {
      LoggerService.log('Editing is disabled');
      return;
    }
    LoggerService.log('option ', option);
    if (Number(option?.option_value) > option.number_min) {
      option.option_value = Number(option?.option_value) - 1;
      if (!this.customField.customerSelectedValue[entityIndex] || this.customField.customerSelectedValue[entityIndex]?.length === 0) {
        this.customField.customerSelectedValue[entityIndex] = [];
      }
      if (this.customField.customerSelectedValue[entityIndex]?.length === 0) {
        this.customField.customerSelectedValue[entityIndex].push({
          custom_field_option_id: option.id,
          custom_field_option_value: option.option_value
        });
      } else {
        const foundedItem: { custom_field_option_id: number, custom_field_option_value: number } =
          this.customField.customerSelectedValue[entityIndex]
            .find((item: { custom_field_option_id: number, custom_field_option_value: number }) => {
              return item.custom_field_option_id === option.id;
            });
        if (foundedItem) {
          for (const optionValue of this.customField.customerSelectedValue[entityIndex]) {
            if (optionValue.custom_field_option_id === option.id) {
              optionValue.custom_field_option_value = option.option_value;
            }
          }
        } else {
          this.customField.customerSelectedValue[entityIndex].push({
            custom_field_option_id: option.id,
            custom_field_option_value: option.option_value
          });
        }
      }
    }
    this.onChangeCustomFieldSelectedValue(option);
  }

  onChangeCustomFieldSelectedValue(additionalEventData?: { [key: string]: any }): void {
    LoggerService.log('this.customField ', this.customField);
    let eventData: { [key: string]: any } = {};

    if (this.grandParentField?.generate_entity_count) {
      eventData.entityNames = this.grandParentField.entity_names;
    } else if (this.parentField?.generate_entity_count) {
      eventData.entityNames = this.parentField.entity_names;
    }

    if (additionalEventData) {
      eventData = { ...eventData, ...additionalEventData };
    }

    this.changeCustomFieldSelectedValueEvent.emit(eventData);
    this.swRouteService.changeCustomFieldStateToIgnore(this.customField.id);
  }

  handleEntityGenerator(option: CustomFieldOptionModel): void {
    if (this.customField.custom_field_options && this.customField.custom_field_options.length > 0) {
      this.customField.generate_entity_count = (option && option.number_of_columns)
        ? Number(option.number_of_columns)
        : 0;

      LoggerService.log('[generate_entity_count]', this.customField.generate_entity_count);

      if (!this.customField.entity_names) { this.customField.entity_names = []; }

      for (let index = 0; index < this.customField.generate_entity_count; index++) {
        this.customField.entity_names[index] = (this.customField.entity_names[index] ? this.customField.entity_names[index] : null);
      }
    }
  }

  onBlurCustomFieldSelectedValue(): void {
    const eventData: { [key: string]: any } = {};
    if (this.parentField && this.parentField.generate_entity_count) {
      eventData.entityNames = this.parentField.entity_names;
    }

    this.blurCustomFieldSelectedValueEvent.emit(eventData);
  }

  onFileChanged(evt: any, entityIndex = 0): void {
    const target = <DataTransfer>evt.target;

    if (!this.isEditAllowed || !target.files?.length) { return; }

    this.customFieldsService.imageUploaded = false;
    this.customField.errorMessage = {};

    let largeFileSize = false;
    let invalidFileType = false;

    if (Number(this.customField.is_multiple_file) === 1 && target.files.length > 5) {
      this.customField.errorMessage[entityIndex] = 'error_messages.maximum_x_files';
      this.customField.customerSelectedInputValue[entityIndex] = '';
      this.waitForFileUpload[entityIndex] = false;
      this.customFieldsService.imageUploaded = true;
      return;
    }

    if (Number(this.customField.is_multiple_file) === 1 && this.customField.customerSelectedFileValue[entityIndex]?.length) {
      const totalFileLength = target.files.length + this.customField.customerSelectedFileValue[entityIndex].length;
      if (totalFileLength > 5) {
        this.customField.errorMessage[entityIndex] = 'error_messages.maximum_x_files';
        this.customField.customerSelectedInputValue[entityIndex] = '';
        this.waitForFileUpload[entityIndex] = false;
        this.customFieldsService.imageUploaded = true;
        return;
      }
    }

    for (let index = 0; index < target?.files?.length; index++) {
      const file = target.files[index];

      if (this.customField.customSupportedMimeType?.split(',')?.indexOf(file.type) === -1) {
        invalidFileType = true;
        this.customField.errorMessage[entityIndex] = this.customField.supported_mime_type === 'pdf'
          ? 'error_messages.document_invalid_file_type'
          : 'error_messages.image_invalid_file_type';
        this.customField.customerSelectedInputValue[entityIndex] = '';
        this.waitForFileUpload[entityIndex] = false;
        this.customFieldsService.imageUploaded = true;
        break;
      }

      if (!invalidFileType && file.size > Number(this.maxFileSize)) {
        this.customField.errorMessage[entityIndex] = 'error_messages.max_file_size_5mb';
        this.customField.customerSelectedInputValue[entityIndex] = '';
        largeFileSize = true;
        this.waitForFileUpload[entityIndex] = false;
        this.customFieldsService.imageUploaded = true;
        break;
      }
    }

    if (invalidFileType || largeFileSize) { return; }

    this.waitForFileUpload[entityIndex] = true;

    if (this.customerDataIsNotMandatoryToUploadFiles) {
      this.uploadCustomFieldFiles(target, entityIndex);
    } else {
      this.uploadCustomerCustomFieldFiles(target, entityIndex);
    }
  }

  uploadCustomFieldFiles(target: DataTransfer, entityIndex = 0): void {
    this.customFieldsService.uploadCustomFieldFiles(target.files, this.customField).subscribe({
      next: result => {
        this.customFieldsService.imageUploaded = true;
        this.createCustomFieldFileValues(result, entityIndex);
      },
      error: (errorResponse: HttpErrorResponse) => {
        this.customField.customerSelectedInputValue[entityIndex] = '';
        LoggerService.error(errorResponse);
        // setting imageUploaded to true even file is failed to upload reason
        // to let user to submit form when first image attampt is failed and they want to
        // continue without uploading files
        if (this.customField.required === 0) {
          this.customFieldsService.imageUploaded = true;
        }
        this.waitForFileUpload[entityIndex] = false;
        if (errorResponse.error && errorResponse.statusText === 'file-size') {
          this.customField.errorMessage[entityIndex] = 'error_messages.max_file_size_5mb';
        } else if (errorResponse.error && errorResponse.statusText === 'malware') {
          this.customField.errorMessage[entityIndex] = errorResponse.error.message;
        } else {
          this.customField.errorMessage[entityIndex] = 'error_messages.something_went_wrong';
        }
      }
    });
  }

  uploadCustomerCustomFieldFiles(target: DataTransfer, entityIndex = 0): void {
    this.customFieldsService.uploadCustomerCustomFieldFiles(
      target.files,
      this.customField,
      this.surveyService.customerDetail.id as number
    ).subscribe({
      next: customerCustomFields => {
        this.waitForFileUpload[entityIndex] = false;
        this.customFieldsService.imageUploaded = true;

        if (!customerCustomFields) {
          return;
        }
        if (!this.surveyService.customerDetail.custom_fields) {
          this.surveyService.customerDetail.custom_fields = [];
        }

        this.surveyService.customerDetail.custom_fields = this.surveyService.customerDetail.custom_fields.concat(customerCustomFields);
        this.createFileList(entityIndex);
      },
      error: (errorResponse: HttpErrorResponse) => {
        this.customField.customerSelectedInputValue[entityIndex] = '';
        LoggerService.error(errorResponse);
        // setting imageUploaded to true even file is failed to upload reason
        // to let user to submit form when first image attampt is failed and they want to
        // continue without uploading files
        if (this.customField.required === 0) {
          this.customFieldsService.imageUploaded = true;
        }
        this.waitForFileUpload[entityIndex] = false;
        if (errorResponse.error && errorResponse.statusText === 'file-size') {
          this.customField.errorMessage[entityIndex] = 'error_messages.max_file_size_5mb';
        } else if (errorResponse.error && errorResponse.statusText === 'malware') {
          this.customField.errorMessage[entityIndex] = errorResponse.error.message;
        } else {
          this.customField.errorMessage[entityIndex] = 'error_messages.something_went_wrong';
        }
      }
    });
  }

  updateMimeTypes(): void {
    this.customField.customSupportedMimeType = '';
    if (this.customField.supported_mime_type) {
      if (this.customField.supported_mime_type.indexOf(',') === -1) {
        if (this.customField.supported_mime_type === 'image') {
          this.customField.customSupportedMimeType = MIME_TYPES.IMAGE;
        } else if (this.customField.supported_mime_type === 'pdf') {
          this.customField.customSupportedMimeType = MIME_TYPES.DOC;
        }
      } else {
        const types: string[] = (this.customField.supported_mime_type as string).split(',');
        for (const type of types) {
          if (type === 'image') {
            this.customField.customSupportedMimeType = this.customField.customSupportedMimeType + MIME_TYPES.IMAGE + ',';
          } else if (type === 'pdf') {
            this.customField.customSupportedMimeType = this.customField.customSupportedMimeType + MIME_TYPES.DOC + ',';
          }
        }
      }
    }
  }

  toggleFileSelection(
    event: MatCheckboxChange,
    file: {
      file: CustomCustomFieldFileDbModel,
      customerCustomFieldUuids: string, selected: boolean
    }): void {
    if (event.checked) {
      this.selectedFiles.push(file.customerCustomFieldUuids);
    } else {
      this.selectedFiles = this.selectedFiles.filter((item: string) => {
        return item !== file.customerCustomFieldUuids;
      });
    }
  }

  deleteFile(
    file: {
      file: CustomCustomFieldFileDbModel,
      customerCustomFieldUuids: string
    },
    index: number,
    entityIndex = 0
  ): void {
    if (!this.isEditAllowed) {
      return;
    }
    if (file.customerCustomFieldUuids) {
      this.customFieldsService.deleteCustomerCustomFieldFile(file.customerCustomFieldUuids).subscribe({
        next: () => {
          this.surveyService.customerDetail.custom_fields = this.surveyService.customerDetail.custom_fields
            .filter((customerCustomField: CustomerCustomFieldDbModel) => {
              if (customerCustomField.file && customerCustomField.file.id === file.file.id) {
                return false;
              } else {
                return true;
              }
            });
          // LoggerService.log('after this.customer.custom_fields ', this.customer.custom_fields);
          this.customField.customerSelectedFileValue[entityIndex].splice(index, 1);
          this.selectedFiles.splice(this.selectedFiles.indexOf(file.customerCustomFieldUuids), 1);
          this.setCustomerFileSelectedValue(entityIndex);
        },
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      });
    } else {
      this.customFieldsService.deleteFile(file.file.uuid, this.customField.id).subscribe({
        next: (result: { success: boolean }) => {
          LoggerService.log('result ', result);
          this.customField.customerSelectedFileValue[entityIndex].splice(index, 1);
          this.selectedFiles.splice(this.selectedFiles.indexOf(file.customerCustomFieldUuids), 1);
          this.setCustomerFileSelectedValue(entityIndex);
        },
        error: (error: HttpErrorResponse) => {
          LoggerService.error(error);
        }
      });
    }
  }

  setCustomerFileSelectedValue(entityIndex = 0): void {
    if (this.customField.customerSelectedFileValue[entityIndex]?.length > 0) {
      this.customField.customerSelectedValue[entityIndex] = this.customField.customerSelectedFileValue[entityIndex]
        .map(item => item.file.id);
    } else {
      this.customField.customerSelectedValue[entityIndex] = [];
    }
    if (this.customField.customerSelectedValue[entityIndex]?.length === 0) {
      this.customField.customerSelectedInputValue[entityIndex] = [];
    }
    this.waitForFileUpload[entityIndex] = false;
  }

  getFilesByFileIds(fileIds: number[]): void {
    if (!fileIds || fileIds?.length === 0) {
      LoggerService.warn('File ids are missing');
      return;
    }
    this.customFieldsService.getFilesByFileIds(fileIds).subscribe({
      next: (result: CustomCustomFieldFileDbModel[]) => {
        this.createCustomFieldFileValues(result);
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  createCustomFieldFileValues(uploadedFiles: CustomCustomFieldFileDbModel[], entityIndex = 0): void {
    LoggerService.log('uploadedFiles ', uploadedFiles);
    if (!uploadedFiles) {
      this.waitForFileUpload[entityIndex] = false;
      return;
    }

    const finalData: { custom_field_id: number, file_ids: number[], type: string } = {
      custom_field_id: this.customField.id,
      file_ids: [],
      type: CUSTOM_FIELD_TYPES.CUSTOM_FIELD_FILE
    };

    for (const fileResult of (uploadedFiles as CustomCustomFieldFileDbModel[])) {
      finalData.file_ids.push(fileResult.id);
    }

    // this.customerService.setCustomerCustomFieldFilesFormDataInLocalStorage(finalData);

    if (Number(this.customField.is_multiple_file) === 1) {
      if (!this.customField.customerSelectedFileValue[entityIndex]) {
        this.customField.customerSelectedFileValue[entityIndex] = [];
      }
    } else {
      this.customField.customerSelectedFileValue[entityIndex] = [];
    }

    LoggerService.log('this.customField.customerSelectedFileValue ', this.customField.customerSelectedFileValue);

    for (const fileResult of (uploadedFiles as CustomCustomFieldFileDbModel[])) {
      this.customField.customerSelectedFileValue[entityIndex].push({
        file: fileResult,
        customerCustomFieldUuids: undefined, // TODO need to verify what to pass here
        selected: false,
      });
    }
    this.setCustomerFileSelectedValue(entityIndex);
    // this.customField.customerSelectedInputValue = null;

    this.updateMimeTypes();
  }

  openYoutubeVideo(option: CustomFieldOptionModel, youtubeElement: any): void {
    let position: { top: string };
    LoggerService.log('Inside openYoutubeVideo() youtubeElement ', youtubeElement);
    if (this.widgetService.layoutType === 'IFRAME') {
      const youtubeElementRef: ElementRef = new ElementRef(youtubeElement);
      LoggerService.log('youtubeElementRef ', youtubeElementRef);
      let dialogTop = 100;
      if (youtubeElementRef.nativeElement) {
        const temp: any = youtubeElementRef.nativeElement.getBoundingClientRect();
        LoggerService.log('Anchor element tag position is ', temp);
        if (temp?.top) {
          dialogTop = temp.top - 100;
        }
      }
      position = {
        top: `${dialogTop}px`
      };
    }

    const matDialogConfig: MatDialogConfig = {
      data: {
        youtubeUrl: option.youtube_url
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '550px',
    };

    if (this.widgetService.layoutType === 'IFRAME') {
      matDialogConfig.position = position;
    }

    this.matDialog.open(YoutubeModalComponent, matDialogConfig).afterClosed().subscribe(
      (result: { success: boolean }) => {
        // LoggerService.log('final result is ', result);
        if (result && result.success) {

        }
      }
    );
  }

  openImagePopup(option: CustomFieldOptionModel, imageElement: any): void {
    let position: { top: string };
    if (this.widgetService.layoutType === 'IFRAME') {
      const imageElementRef: ElementRef = new ElementRef(imageElement);
      let dialogTop = 100;
      if (imageElementRef.nativeElement) {
        const temp: any = imageElementRef.nativeElement.getBoundingClientRect();
        dialogTop = temp?.top ? (temp.top - 100) : dialogTop;
      }
      position = {
        top: `${dialogTop}px`
      };
    }

    const matDialogConfig: MatDialogConfig = {
      data: {
        imageUrl: option.image
      },
      panelClass: 'calenso-image-popup-dialog-box',
      width: '550px',
    };

    if (imageElement?.naturalHeight && imageElement?.naturalWidth) {
      matDialogConfig.width = `${imageElement?.naturalWidth}px`;
    }

    if (this.widgetService.layoutType === 'IFRAME') {
      matDialogConfig.position = position;
    }

    this.matDialog.open(ImageModalComponent, matDialogConfig).afterClosed().subscribe(
      (result: { success: boolean }) => {
        if (result && result.success) {

        }
      }
    );
  }

  private createFileList(entityIndex = 0): void {
    this.customField.customerSelectedFileValue[entityIndex] = [];
    if (this.customField.type === CUSTOM_FIELD_TYPES.CUSTOM_FIELD_FILE) {
      // LoggerService.log('this.customField ', this.customField);
      for (const customerCustomField of this.surveyService.customerDetail.custom_fields) {
        if (customerCustomField.custom_field_id === this.customField.id) {
          this.customField.customerSelectedFileValue[entityIndex].push({
            file: customerCustomField.file,
            customerCustomFieldUuids: customerCustomField.uuid,
            selected: false,
          });
        }
      }
      this.updateMimeTypes();
      this.setCustomerFileSelectedValue(entityIndex);
    }
  }

  hasOptionWithFilledImage(entityIndex = 0): boolean {
    const hasOption = this.customField.custom_field_options[entityIndex].find(
      (option: CustomFieldOptionModel) => option.is_fullscreen_image === 1
    );
    return hasOption ? true : false;
  }

  preSelectWorkerAndStore(worker: WorkerDbModel): void {
    if (worker) {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState = this.bookingService.resetCurrentSelectedAppointmentStateonStoreSelection();

      // set pre-selected worker and store flag
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.storeAndWorkerPreSelectedThroughWorkerSelectField = true;

      // pre-select store
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId = worker.store_id;
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore = worker.store;

      // pre-select worker
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId = worker.id;
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorker = worker;

      if (
        !worker.store.is_online && (
          this.widgetService?.widgetConf?.partner?.enable_store_postcode_feature === 1 ||
          this.widgetService?.widgetConf?.partner?.automatic_store_zip_search === 1
        )) {
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreLeadGeneratorPostcode = worker.store.zip;
      }

      this.bookingService.setAppointmentStateInLocalStorage(this.bookingService.selectedBookingData.currentSelectedAppointmentState);
    }
  }
}

