import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SlotsCardSelectionComponent } from '@components/slots-card-selection/slots-card-selection.component';
import { BOOKING_PAGE_VIEWS, CALENSO_SCROLL_EVENT, GTM_EVENTS } from '@constants/app.constants';
import { DATE_ISO_FORMAT, TIME_FORMAT } from '@constants/date.constants';
import { AppointmentsSlotsDao } from '@models/availability.model';
import { WidgetAppointmentGroupModel } from '@models/widget-appointment-group.model';
import { CurrentViewData } from '@models/widget-conf.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioMeetingTemplatePipe } from '@pipes/calio-meeting-template.pipe';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { BookingService } from '@services/feature/booking.service';
import { CustomEventService } from '@services/feature/custom-event.service';
import { WidgetService } from '@services/feature/widget.service';
import { DateUtilService } from '@services/utils/date-util.service';
import { GoogleAnalyticsService } from '@services/utils/google-analytics.service';
import { HelperService } from '@services/utils/helper.service';
import { LoggerService } from '@services/utils/logger.service';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { CalioCalendarPickerComponent } from '@ui-lib/calio-calendar/calio-calendar-picker/calio-calendar-picker.component';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';
import { WeekDay } from 'calendar-utils';
import * as moment from 'moment';
@Component({
  selector: 'app-slots-selection',
  templateUrl: './slots-selection.component.html',
  styleUrls: ['./slots-selection.component.scss'],
  standalone: true,
  imports: [CswTextComponent, CalioCalendarPickerComponent, SlotsCardSelectionComponent, ButtonComponent, CalioTranslationPipe, CalioMeetingTemplatePipe, TranslateModule, CalioSafePipe]
})
export class SlotsSelectionComponent implements OnInit, OnDestroy {

  readonly dateIsoFormat = DATE_ISO_FORMAT;
  readonly dateTimeIsoFormat = DATE_ISO_FORMAT;
  readonly timeFormat = TIME_FORMAT;

  protected widgetGroups: WidgetAppointmentGroupModel[] = [];
  protected availableDays: string[] = [];
  protected showSlotGhostElement = false;
  protected freeAppointments: {
    dayPeriod: string,
    appointments: AppointmentsSlotsDao[],
    showDayPeriodLabel?: boolean,
    widgetGroup: WidgetAppointmentGroupModel
  }[] = [];
  protected freeAppointmentsCount = 0;
  protected noAppointments = false;
  protected selectedAppointment: AppointmentsSlotsDao;
  protected selectedDate: Date;
  protected slotGhostElements = [1, 2, 3, 4, 5, 6];
  protected lang: string;
  protected loadCalendarPicker = false;
  protected isGTMEventSent: boolean;
  protected availableSlotsArray: AppointmentsSlotsDao[] = [];
  private timeoutId: ReturnType<typeof setTimeout> | null = null;
  protected showSlotsSelectionRequiredError = false;

  @Output() nextPageEvent = new EventEmitter<CurrentViewData>();
  @Output() previousPageEvent = new EventEmitter<CurrentViewData>();

  constructor(
    public bookingService: BookingService,
    private dateUtilService: DateUtilService,
    private customEventService: CustomEventService,
    public widgetService: WidgetService,
    private translate: TranslateService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private helperService: HelperService
  ) {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit(): void {
    this.isGTMEventSent = false;
    this.bookingService.loadAppointmentCartItemsFromLocalStorage();
    this.loadCalendarPicker = true;
    this.getWidgetAppointmentGroups();
    if (this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedDate) {
      this.selectedDate = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedDate;
    }
    this.initAppointments();
  }

  initAppointments(): void {
    if (this.widgetService?.widgetConf?.context?.show_meeting_type_picker &&
      this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId !== 1
    ) {
      if (
        this.bookingService.selectedBookingData &&
        (
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId ||
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId === 0
        ) &&
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds &&
        (
          this.bookingService.selectedBookingData.currentSelectedAppointmentState?.selectedWorker?.id ||
          this.bookingService.selectedBookingData.currentSelectedAppointmentState?.selectedWorker?.id === 0
        )
      ) {
        if (this.bookingService.selectedBookingData.currentSelectedAppointmentState?.selectedWorker?.id === 0) {
          this.getAllExceptionDays(
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId,
            0,
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.join(),
            this.selectedDate ? this.selectedDate : new Date()
          );
        } else {
          this.getAllExceptionDays(
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId,
            this.bookingService.selectedBookingData.currentSelectedAppointmentState?.selectedWorker?.store?.id,
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.join(),
            this.selectedDate ? this.selectedDate : new Date()
          );
        }
      } else {
        // TODO handling false case
        LoggerService.warn('SMART_WIDGET_WARNING: Worker, service and worker date is mandatory');
      }
    } else {
      if (
        this.bookingService.selectedBookingData &&
        (
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId
          || this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId === 0
        ) &&
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds &&
        (
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId ||
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId === 0
        )
      ) {
        this.getAllExceptionDays(
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId,
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId,
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.join(),
          this.selectedDate ? this.selectedDate : new Date()
        );
      } else {
        // TODO handling false case
        LoggerService.warn('SMART_WIDGET_WARNING: Worker, service and store selection is mandatory');
      }
    }
  }

  onSelectedDays(data: {
    day: WeekDay[],
    isUserInteracted: boolean
  }): void {
    // Reset Error
    this.showSlotsSelectionRequiredError = false;
    this.noAppointments = false;
    this.freeAppointments = [];
    const event = data.day;
    this.selectedDate = event[0].date;
    this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedDate = this.selectedDate;
    this.getFreeAppointments(this.dateUtilService.getFormattedDateByFormat(event[0].date, this.dateIsoFormat));

    this.bookingService.setAppointmentStateInLocalStorage(this.bookingService.selectedBookingData.currentSelectedAppointmentState);

    if (data.isUserInteracted) {
      this.bookingService.triggerApoointmentStartEvent();

      if (!this.isGTMEventSent) {
        this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_available_appointments_start);
        this.isGTMEventSent = true;
      }
    }
  }

  onMonthChangedEvent(event: Date): void {
    if (
      this.widgetService?.widgetConf?.context?.show_meeting_type_picker &&
      this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId !== 1
    ) {
      if (this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId === 0) {
        this.getAllExceptionDays(
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId,
          0,
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.join(),
          event
        );
      } else {
        this.getAllExceptionDays(
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId,
          this.bookingService.selectedBookingData.currentSelectedAppointmentState?.selectedWorker?.store?.id,
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.join(),
          event
        );
      }
    } else {
      this.getAllExceptionDays(
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId,
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId,
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.join(),
        event
      );
    }
  }

  previous(): void {
    this.isGTMEventSent && this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_available_appointments_end);
    this.previousPageEvent.emit({ view: BOOKING_PAGE_VIEWS.WORKERS_VIEW, isUserInteracted: true });
  }

  next(): void {
    // Slots are not selected and clicked on next then show error.
    if (!(this.bookingService?.selectedBookingData?.cartItems?.length > 0)) {
      this.showSlotsSelectionRequiredError = true;
      return;
    }

    this.bookingService.triggerApoointmentStartEvent();

    if (!this.isGTMEventSent) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_available_appointments_start);
      this.isGTMEventSent = true;
    }

    this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_available_appointments_end);
    if (this.widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
      this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.PERSONAL_FORM_VIEW, isUserInteracted: true });
    } else {
      if (this.widgetService.widgetConf?.context?.booking_questions?.length > 0) {
        this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.BOOKING_QUESTIONS_VIEW_AFTER, isUserInteracted: true });
      } else {
        this.nextPageEvent.emit({ view: BOOKING_PAGE_VIEWS.PERSONAL_FORM_VIEW, isUserInteracted: true });
      }
    }
  }

  private getAllExceptionDays(workerId: number, storeId: number, appointmentIds: string, date: Date): void {
    let startDate: string;
    if (this.dateUtilService.isSame(date, new Date(), 'month')) {
      startDate = this.dateUtilService.getFormattedDateByFormat(new Date(), this.dateIsoFormat);
    } else {
      startDate = this.dateUtilService.getFormattedDateByFormat(this.dateUtilService.getStartOf(date, 'month'), this.dateIsoFormat);
    }

    this.bookingService.getDaysWiseFreeSlots(workerId, storeId, appointmentIds, startDate).subscribe({
      next: availableSlots => {
        this.availableSlotsArray = [];
        if (availableSlots && !('errors' in availableSlots)) {
          // preparing array that has day wise availabilites
          availableSlots.available_minutes_blocks?.forEach(daysWiseSlots => {
            daysWiseSlots.workers_minutes_blocks?.forEach(workerMinutesBlock => {
              workerMinutesBlock.minutes_blocks.forEach(availabilities => {
                availabilities.appointments_slots?.forEach(slots => {
                  const selectedWorkerId = Number(this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId);
                  const hasAnyoneFeatureEnabled = selectedWorkerId > 0 ? selectedWorkerId : 0;
                  let workerUuids = Array<string>();
                  if (hasAnyoneFeatureEnabled === 0) {
                    workerUuids = [workerMinutesBlock.worker.uuid];
                  } else {
                    workerUuids = workerMinutesBlock.workers.map(worker => worker.uuid);
                  }

                  !this.availableSlotsArray[daysWiseSlots.date] && (this.availableSlotsArray[daysWiseSlots.date] = []);
                  this.availableSlotsArray[daysWiseSlots.date].push({
                    start: slots.appointment_start,
                    end: slots.appointment_end,
                    worker: workerMinutesBlock.worker,
                    workerUuids,
                    store: workerMinutesBlock.store,
                    startHour: parseInt(slots.appointment_start.split(' ')[1].split(':')[0], 10),
                    short_start: slots.appointment_short_start,
                  });
                });
              });
            });
          });
        }

        if (Object.keys(this.availableSlotsArray)?.length > 0) {
          this.availableDays = Object.keys(this.availableSlotsArray).map((key) => key);
        } else {
          this.availableDays = [];
        }

        if (!this.availableDays.includes(this.dateUtilService.getFormattedDateByFormat(this.selectedDate, this.dateIsoFormat))) {
          this.selectedDate = undefined
        }
        this.customEventService.refreshCalendar.next('next');
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  private getWidgetAppointmentGroups(): void {
    this.bookingService.getWidgetAppointmentGroups().subscribe({
      next: (widgetGroups: WidgetAppointmentGroupModel[]) => {
        !widgetGroups && (widgetGroups = []);
        widgetGroups.forEach(widgetGroup => widgetGroup.startHour = parseInt((widgetGroup.start as string).split('T')[1].split(':')[0], 10))
        this.widgetGroups = widgetGroups.sort((a, b) => a.startHour - b.startHour);
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  private getFreeAppointments(date: string): void {
    this.freeAppointmentsCount = 0;
    this.showSlotGhostElement = true;
    this.timeoutId = setTimeout(() => {
      if (Number(this.bookingService.partnerData?.scroll_to_element_feature_is_enabled) === 1) {
        const slotsCardSection = document.getElementById('slotsCardSection');
        const parentOrigin = window.location.ancestorOrigins[0];
        if (this.helperService.isIosDevice()) {
          window.parent.postMessage({ eventName: CALENSO_SCROLL_EVENT, data: { top: slotsCardSection.getBoundingClientRect().top } }, parentOrigin || '*');
        } else {
          slotsCardSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
      this.showSlotGhostElement = false;
    }, 500);

    this.customEventService.resetSlotSettingsEvent.emit();

    let freeAppointments: AppointmentsSlotsDao[];
    freeAppointments = this.availableSlotsArray[date];
    this.freeAppointmentsCount = freeAppointments.length;
    this.freeAppointments = [];
    freeAppointments.forEach(appointment => appointment.startHour = parseInt(appointment.start.split(' ')[1].split(':')[0], 10))
    freeAppointments = freeAppointments.sort((a, b) => a.startHour - b.startHour);

    let tempFreeAppointments: {
      dayPeriod: string,
      appointments: AppointmentsSlotsDao[],
      showDayPeriodLabel?: boolean,
      widgetGroup: WidgetAppointmentGroupModel
    }[] = [];

    this.widgetGroups?.forEach(widgetGroup => {
      tempFreeAppointments.push({
        dayPeriod: widgetGroup.name,
        appointments: [],
        showDayPeriodLabel: false,
        widgetGroup
      });
    });

    tempFreeAppointments.push({
      dayPeriod: undefined,
      appointments: [],
      showDayPeriodLabel: false,
      widgetGroup: undefined
    });

    if (this.widgetGroups?.length) {
      const notGroupedAppointments: AppointmentsSlotsDao[] = [];
      for (let i = 0; freeAppointments.length > i; i++) {
        for (const freeAppointmentGroup of tempFreeAppointments) {
          if (freeAppointmentGroup.widgetGroup) {
            const freeAppointmentStart = moment(moment().format(this.dateIsoFormat) + ' ' + moment(freeAppointments[i].start).format(this.timeFormat), this.dateTimeIsoFormat).toDate();
            const freeAppointmentEnd = moment(moment().format(this.dateIsoFormat) + ' ' + moment(freeAppointments[i].end).format(this.timeFormat), this.dateTimeIsoFormat).toDate();
            let widgetGroupStart = moment(freeAppointmentGroup.widgetGroup.start, this.dateTimeIsoFormat).toDate();
            let widgetGroupEnd = moment(freeAppointmentGroup.widgetGroup.end, this.dateTimeIsoFormat).toDate();
            widgetGroupStart = moment(moment().format(this.dateIsoFormat) + ' ' + moment(widgetGroupStart).format(this.timeFormat), this.dateTimeIsoFormat).toDate();
            widgetGroupEnd = moment(moment().format(this.dateIsoFormat) + ' ' + moment(widgetGroupEnd).format(this.timeFormat), this.dateTimeIsoFormat).toDate();

            if (moment(widgetGroupStart).isAfter(moment(widgetGroupEnd))) {
              widgetGroupEnd = moment(widgetGroupEnd).add(1, 'day').toDate();
              if (
                (
                  moment(freeAppointmentStart).isSameOrAfter(moment(widgetGroupStart)) ||
                  moment(freeAppointmentStart).add(1, 'day').isBetween(moment(widgetGroupStart), moment(widgetGroupEnd), undefined, '[]')
                ) &&
                moment(freeAppointmentEnd).isSameOrBefore(moment(widgetGroupEnd))
              ) {
                freeAppointments[i].isGrouped = true;
                freeAppointmentGroup.appointments.push(freeAppointments[i]);
                freeAppointmentGroup.showDayPeriodLabel = true;
                break;
              }
            } else {
              if (
                moment(freeAppointmentStart).isSameOrAfter(moment(widgetGroupStart)) &&
                moment(freeAppointmentStart).isBefore(moment(widgetGroupEnd))
              ) {
                freeAppointments[i].isGrouped = true;
                freeAppointmentGroup.appointments.push(freeAppointments[i]);
                freeAppointmentGroup.showDayPeriodLabel = true;
                break;
              }
            }

          }
        }
        (!freeAppointments[i].isGrouped) && notGroupedAppointments.push(freeAppointments[i]);
      }
      if (notGroupedAppointments.length > 0) {
        tempFreeAppointments[tempFreeAppointments.length - 1].showDayPeriodLabel = true;
        tempFreeAppointments[tempFreeAppointments.length - 1].appointments = Object.assign([], notGroupedAppointments);
      }
    } else {
      for (let i = 0; freeAppointments.length > i; i++) {
        tempFreeAppointments[tempFreeAppointments.length - 1].showDayPeriodLabel = true;
        tempFreeAppointments[tempFreeAppointments.length - 1].appointments.push(freeAppointments[i]);
      }
    }

    let appointmentOrderIndex = 0;
    tempFreeAppointments.forEach(tempFreeAppointment => {
      tempFreeAppointment.appointments = tempFreeAppointment.appointments ? tempFreeAppointment.appointments : [];
      (appointmentOrderIndex > 9) && (tempFreeAppointment.showDayPeriodLabel = false);
      tempFreeAppointment.appointments?.forEach(appointment => appointment.appointmentOrderIndex = appointmentOrderIndex++);
    })

    this.freeAppointments = tempFreeAppointments.filter(appointmentGroup => appointmentGroup?.appointments?.length > 0);

    (!freeAppointments || freeAppointments.length === 0) && (this.noAppointments = true);
  }

  ngOnDestroy(): void {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
  }
}
